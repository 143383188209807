<template>
  <BasicTemplate v-if="designID == 'iLjAw41CX5E9YjPgA0KA' || designID == undefined"
                :companyNameComponent="companyName"
                :contactNumberComponent="contactNumber"
                :emailComponent="email"
                :fbComponent="fb"
                :instaComponent="insta"
                :linkedinComponent="linkedin"
                :nameComponent="name"
                :profileImgComponent="profileImg"
                :roleComponent="role"
                :twitterComponent="twitter"
                :websiteComponent="website"
                :addressComponent="address"
                :officeNumberComponent="officeNumber"
                :faxNumberComponent="faxNumber"
                :descriptionComponent="description"
                :youtubeComponent="youtube"
                :tiktokComponent="tiktok"
                :whatsappComponent="whatsapp"
                :teleComponent="tele"
                :snapchatComponent="snapchat"
                :socialArrComponent="socialArr"
                :picComponent="pic"
                :counterComponent="counter"
                :galleryImagesComponent="galleryImages"
                :pdfFileComponent="pdfFile"
                :pdfNameComponent="pdfName"

            />
     <BasicTemplate2 v-else-if="designID == 'M2ndkvBJc1fc67Ts7YJv'"
                :companyNameComponent="companyName"
                :contactNumberComponent="contactNumber"
                :emailComponent="email"
                :fbComponent="fb"
                :instaComponent="insta"
                :linkedinComponent="linkedin"
                :nameComponent="name"
                :profileImgComponent="profileImg"
                :roleComponent="role"
                :twitterComponent="twitter"
                :websiteComponent="website"
                :addressComponent="address"
                :officeNumberComponent="officeNumber"
                :faxNumberComponent="faxNumber"
                :descriptionComponent="description"
                :youtubeComponent="youtube"
                :tiktokComponent="tiktok"
                :whatsappComponent="whatsapp"
                :teleComponent="tele"
                :snapchatComponent="snapchat"
                :socialArrComponent="socialArr"
                :picComponent="pic"
                :counterComponent="counter"
                :galleryImagesComponent="galleryImages"
                :pdfFileComponent="pdfFile"
                :pdfNameComponent="pdfName"

            />
    <HFDarkTemplate v-else-if="designID == 'FcWGfuVirJRo2B51Tr5l'"
                :companyNameComponent="companyName"
                :contactNumberComponent="contactNumber"
                :emailComponent="email"
                :fbComponent="fb"
                :instaComponent="insta"
                :linkedinComponent="linkedin"
                :nameComponent="name"
                :profileImgComponent="profileImg"
                :roleComponent="role"
                :twitterComponent="twitter"
                :websiteComponent="website"
                :addressComponent="address"
                :officeNumberComponent="officeNumber"
                :faxNumberComponent="faxNumber"
                :descriptionComponent="description"
                :youtubeComponent="youtube"
                :tiktokComponent="tiktok"
                :whatsappComponent="whatsapp"
                :teleComponent="tele"
                :snapchatComponent="snapchat"
                :socialArrComponent="socialArr"
                :picComponent="pic"
                :counterComponent="counter"


            />
      <HFLightTemplate v-else-if="designID == 'bvWHcqOYNiOtEbUx2FQV'"
                :companyNameComponent="companyName"
                :contactNumberComponent="contactNumber"
                :emailComponent="email"
                :fbComponent="fb"
                :instaComponent="insta"
                :linkedinComponent="linkedin"
                :nameComponent="name"
                :profileImgComponent="profileImg"
                :roleComponent="role"
                :twitterComponent="twitter"
                :websiteComponent="website"
                :addressComponent="address"
                :officeNumberComponent="officeNumber"
                :faxNumberComponent="faxNumber"
                :descriptionComponent="description"
                :youtubeComponent="youtube"
                :tiktokComponent="tiktok"
                :whatsappComponent="whatsapp"
                :teleComponent="tele"
                :snapchatComponent="snapchat"
                :socialArrComponent="socialArr"
                :picComponent="pic"
                :counterComponent="counter"

            />
            
      <DarkTemplate v-else-if="designID == 'ditK7TpNpjG3sXEwGxBy'" style="background-color:#232323; overflow-x:hidden;"
                :companyNameComponent="companyName"
                :contactNumberComponent="contactNumber"
                :emailComponent="email"
                :fbComponent="fb"
                :instaComponent="insta"
                :linkedinComponent="linkedin"
                :nameComponent="name"
                :profileImgComponent="profileImg"
                :roleComponent="role"
                :twitterComponent="twitter"
                :websiteComponent="website"
                :addressComponent="address"
                :officeNumberComponent="officeNumber"
                :faxNumberComponent="faxNumber"
                :descriptionComponent="description"
                :youtubeComponent="youtube"
                :tiktokComponent="tiktok"
                :whatsappComponent="whatsapp"
                :teleComponent="tele"
                :snapchatComponent="snapchat"
                :socialArrComponent="socialArr"
                :picComponent="pic"
                :counterComponent="counter"
                :galleryImagesComponent="galleryImages"
                :pdfFileComponent="pdfFile"
                :pdfNameComponent="pdfName"
            />
      <BrochureTemplate v-else-if="designID == 'miGsFqPDISX5mUmaqHDE'"/>
      <VSATTemplate v-else-if="designID == 'wVqWyTugalSFyRFihwGe'" style="background-color: #343434;"
                :companyNameComponent="companyName"
                :contactNumberComponent="contactNumber"
                :emailComponent="email"
                :fbComponent="fb"
                :instaComponent="insta"
                :linkedinComponent="linkedin"
                :nameComponent="name"
                :profileImgComponent="profileImg"
                :roleComponent="role"
                :twitterComponent="twitter"
                :websiteComponent="website"
                :addressComponent="address"
                :officeNumberComponent="officeNumber"
                :faxNumberComponent="faxNumber"
                :descriptionComponent="description"
                :youtubeComponent="youtube"
                :tiktokComponent="tiktok"
                :whatsappComponent="whatsapp"
                :teleComponent="tele"
                :snapchatComponent="snapchat"
                :socialArrComponent="socialArr"
                :picComponent="pic"
                :counterComponent="counter"
                :addtionalInfoComponent ="additionalInfo"
                :pdfFileComponent="pdfFile"
                :pdfNameComponent="pdfName"
                :galleryImagesComponent="galleryImages"
            />
      <CarQuotesTemplate v-else-if="designID == 'keLb1cIMYUhbU0R3Jq0m'"
                :companyNameComponent="companyName"
                :contactNumberComponent="contactNumber"
                :emailComponent="email"
                :fbComponent="fb"
                :instaComponent="insta"
                :linkedinComponent="linkedin"
                :nameComponent="name"
                :profileImgComponent="profileImg"
                :roleComponent="role"
                :twitterComponent="twitter"
                :websiteComponent="website"
                :addressComponent="address"
                :officeNumberComponent="officeNumber"
                :faxNumberComponent="faxNumber"
                :descriptionComponent="description"
                :youtubeComponent="youtube"
                :tiktokComponent="tiktok"
                :whatsappComponent="whatsapp"
                :teleComponent="tele"
                :snapchatComponent="snapchat"
                :socialArrComponent="socialArr"
                :picComponent="pic"
                :counterComponent="counter"
                :addtionalInfoComponent ="additionalInfo"
                :pdfFileComponent="pdfFile"
                :pdfNameComponent="pdfName"
                :galleryImagesComponent="galleryImages"
                :fbRatingComponent="fbrating"
                :googleRatingComponent="googlerating"
            />
      <VSATClientTemplate v-else-if="designID == 'kidYZLj37jaLkmEitd2T'" style="background-color: #343434;"
                :companyNameComponent="companyName"
                :contactNumberComponent="contactNumber"
                :emailComponent="email"
                :fbComponent="fb"
                :instaComponent="insta"
                :linkedinComponent="linkedin"
                :nameComponent="name"
                :profileImgComponent="profileImg"
                :roleComponent="role"
                :twitterComponent="twitter"
                :websiteComponent="website"
                :addressComponent="address"
                :officeNumberComponent="officeNumber"
                :faxNumberComponent="faxNumber"
                :descriptionComponent="description"
                :youtubeComponent="youtube"
                :tiktokComponent="tiktok"
                :whatsappComponent="whatsapp"
                :teleComponent="tele"
                :snapchatComponent="snapchat"
                :socialArrComponent="socialArr"
                :picComponent="pic"
                :counterComponent="counter"
                :addtionalInfoComponent ="additionalInfo"
                :pdfFileComponent="pdfFile"
                :pdfNameComponent="pdfName"
                :galleryImagesComponent="galleryImages"
            />

</template>

<script>
// import { doc, getDoc, onSnapshot,collection,query,orderBy,getDocs } from "firebase/firestore";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseDB";
import router from "@/router";
import BasicTemplate from '../components/BasicTemplate'
import BasicTemplate2 from '../components/BasicTemplate2'
import HFDarkTemplate from '../components/HFDarkTemplate.vue';
import HFLightTemplate from '../components/HFLightTemplate.vue';
import DarkTemplate from '../components/DarkBasicTemplate.vue';
import BrochureTemplate from '../components/BrochureTemplate.vue';
import VSATTemplate from '../components/VSATTemplate.vue';
import CarQuotesTemplate from '../components/CarQuotesTemplate.vue';
import VSATClientTemplate from '../components/VSATClientTemplate.vue';

export default {
  data() {
    return {
      companyName: "",
      contactNumber: "",
      email: "",
      fb: "",
      insta: "",
      linkedin: "",
      name: "",
      profileImg: "",
      role: "",
      twitter: "",
      website: "",
      companyID: "",
      timestamp: "",
      address: "",
      officeNumber:"",
      faxNumber:"",
      description:"",
      counter:0,
      youtube:"",
      tiktok:"",
      whatsapp:"",
      tele:"",
      snapchat:"",
      pic:"",
      socialArr:[],
      designID:"",
      designTemplateDic:{},
      galleryImages:[],
      pdfFile:"",
      pdfName:"",
      additionalInfo:"",
      googlerating: "",
      fbrating:""
    };
  },
   components:{
    BasicTemplate,
    BasicTemplate2,
    HFDarkTemplate,
    HFLightTemplate,
    DarkTemplate,
    BrochureTemplate,
    VSATTemplate,
    CarQuotesTemplate,
    VSATClientTemplate
  },
  async beforeCreate() {
    var userID = this.$route.query.user;
    if (userID == undefined || userID == "") {
      router.push("/NotFound");
    }
    const docRef = doc(db, "userProfile", userID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await onSnapshot(docRef, async (docData) => {
        var userData = docData.data();
       
        // this.companyImg = userData.companyImg
        // this.companyName = userData.companyName
        this.address = userData.address;
        this.contactNumber = userData.contactNumber;
        this.email = userData.email;
        this.fb = userData.fb;
        this.insta = userData.insta;
        this.linkedin = userData.linkedin;
        this.name = userData.name;
        this.profileImg = userData.profileImg;
        this.role = userData.role;
        this.twitter = userData.twitter;
        this.website = userData.website;
        this.companyName = userData.companyName;
        this.officeNumber = userData.officeNumber;
        this.faxNumber = userData.faxNumber;
        this.description = userData.description;
        this.youtube = userData.youtube;
        this.tiktok = userData.tiktok;
        this.whatsapp = userData.whatsapp;
        this.tele = userData.tele;
        this.snapchat = userData.snapchat;
        this.socialArr = []
        this.designID = userData.designOption
        this.pdfFile = userData.PdfFile
        this.pdfName = userData.PdfName
        this.googlerating = userData.googleReview
        this.fbrating = userData.fbReview
        if(userData.additionalInfo!= undefined){
            console.log(userData.additionalInfo)
            this.additionalInfo = userData.additionalInfo
        }else{
            this.additionalInfo = ""
        }

        if (this.fb!= '' && this.fb != undefined){this.counter+=1;this.socialArr.push("fb")}
        if (this.insta!= '' && this.insta != undefined){this.counter+=1;this.socialArr.push("insta")}
        if (this.linkedin!= '' && this.linkedin != undefined){this.counter+=1;this.socialArr.push("linkedin")}
        if (this.twitter!= '' && this.twitter != undefined){this.counter+=1;this.socialArr.push("twitter")}
        if (this.website!= '' && this.website != undefined){this.counter+=1;this.socialArr.push("web")}
        if (this.youtube!= '' && this.youtube != undefined){this.counter+=1;this.socialArr.push("youtube")}
        if (this.tiktok!= '' && this.tiktok != undefined){this.counter+=1;this.socialArr.push("tiktok")}
        if (this.whatsapp!= '' && this.whatsapp != undefined){this.counter+=1;this.socialArr.push("whatsapp")}
        if (this.tele!= '' && this.tele != undefined){this.counter+=1;this.socialArr.push("tele")}
        if (this.snapchat!= '' && this.snapchat != undefined){this.counter+=1;this.socialArr.push("snapchat")}
        if (this.googlerating!= '' && this.snapgoogleratingchat != undefined){this.counter+=1;this.socialArr.push("googlerating")}
        if (this.fbrating!= '' && this.fbrating != undefined){this.counter+=1;this.socialArr.push("fbrating")}
        if (typeof userData.imagePreview !== 'undefined') {
            const keys = Object.keys(userData.imagePreview).sort(); 
            for (let key of keys) {
                if (userData.imagePreview[key] !== "" && userData.imagePreview[key] !== undefined && userData.imagePreview[key] !== null) {
                    this.galleryImages.push(userData.imagePreview[key]);
                }
            }
        } else {
            this.galleryImages = [];
        }

        const docRef2 = doc(db, "designTemplates", this.designID);
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
          var designData = docSnap2.data()
          if (designData.exclusive && !designData.exclusiveList.includes(userID)){
            this.designID="iLjAw41CX5E9YjPgA0KA"
          }
        } else {
          this.designID="iLjAw41CX5E9YjPgA0KA"
        }
        

        
      });
      // const colRef  = collection(db, "designTemplates");
      // const deisgnQuery = query(colRef, orderBy("createdTime"));
      // const designSnap = await getDocs(deisgnQuery)

      // designSnap.forEach(doc => { 
      //   // console.log(doc.data(),doc.id); 
      //   this.designTemplateDic[doc.id] = doc.data()
      //   })
      // // console.log(this.designTemplateDic,this.designID)
      //   // if (userData.designOption)
      // if (!this.designTemplateDic[this.designID].exclusiveList.includes(userID)){
      //   // console.log("here")
      //   this.designID="iLjAw41CX5E9YjPgA0KA"
      // }
      
    } else {
      // doc.data() will be undefined in this case
      router.push("/NotFound");
    }
   
  },
  methods: {
    
  },
};
</script>

<style scoped>
/* .main-wrapper {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 0 15px;
}

.profile-header {
  align-items: center;
}

.card-img-top {
  object-fit: cover;
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -50px;
  width: 100px !important;
  height: 100px !important;
}

.card {
  margin-top: 30px;
  padding: 80px 30px 40px 30px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
}

.profile-role span,
.profile-team span,
.profile-detail-title span {
  font-size: 13px;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 600;
}

.profile-role h6,
.profile-team h6 {
  color: #565656;
  font-weight: 500;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
}

.profile-contact {
  margin-top: 35px;
}

.profile-contact-links {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
}

.profile-contact-link {
  border-radius: 30px;
  padding: 10px 15px;
  background: #188dee;
  animation: name-of-animation 5s infinite;
}

.profile-contact-link {
  cursor: pointer;
}

i {
  color: white;
}

.profile-info {
  margin-top: 50px;
  padding: 0 25px;
}

.save-contact {
  padding: 0 25px;
}

.profile-detail {
  margin-bottom: 15px;
}

h6 a {
  text-decoration: none;
  color: #565656;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 500;
}

.contact-button {
  -webkit-appearance: initial;
  background: #188dee;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  width: 100%;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-top: 30px;
  text-decoration: none;
  text-align: center;
  background-color: #188dee;
  display: inline-block;
}

@keyframes name-of-animation {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} */
</style>