<template>
  <div v-if="showSplashScreen">
      <div  class="splash-container">
          <img class="splash-screen" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/SplashScreen%2FHydroFlux%2FHF_Dark2.gif?alt=media&token=8abb3c48-950c-467f-bc81-8f52bacafb71" alt="Splash Screen" />
      </div>
  </div>
  <div v-else>
    <div>
      <!-- <button @click="showModal = true">Open Modal</button> -->
      <div v-if="showModal">
        <div class="popup" style="text-align:center">  
          <div class="popup-inner" style="position: relative;">
            <button  class="icon-button" @click="showModal = false" >
              <i class="fa fa-times" style="color:white !important;  position: absolute; top:20px;right:20px"></i>
            </button>
            <div>
              <canvas ref="qrcodeImg"></canvas>
              <p style="color:white;">Scan To Find Me!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <img
        class="card-img-top"
        src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/Banner%2FHFbanner.jpg?alt=media&token=3626bb9f-35f3-4c78-862f-cca1af86d96c"
        alt="Card image cap"
      />
      <div class="card-body little-profile text-center">
        <div class="pro-img dark:border-dark">
          <img :src="profileImg" alt="user" />
        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <h3 class="m-b-0" style="color:white !important; margin-right: 10px; margin-bottom:0px !important;">{{this.name }}</h3>
          <button class="icon-button" @click="openModal" style="display: flex; align-items: center;">
            <i class="fa fa-qrcode" style="color:white !important;"></i>
          </button>
          <button  class="icon-button" @click="shareLink" >
            <i class="fa fa-share-alt" style="color:white !important;"></i>
        </button>
        </div>
        <div class="row justify-content-center">{{this.companyName}}</div>
        <div class="row fw-light fst-italic justify-content-center title-role">
          <span>{{ this.role }}</span>
        </div>
      </div>
      <div class="container-fluid px-3">
        <div class="row justify-content-center" >
          <button  v-if="this.contactNumber != ''  &&  this.contactNumber!= undefined"
            type="button"
            class="btn btn-default btn-circle-md dark:text-white"
          >
            <a :href="'tel:' + this.contactNumber">
              <i class="fa-solid fa-phone"></i>
            </a>
          </button>
          <button 
            v-on:click="saveContact()"
            type="button"
            class="btn btn-default btn-circle-md dark:text-white mx-3"
          >
            <i class="fa-solid fa-plus"></i>
          </button>
          <button v-if="this.email != ''  &&  this.email!= undefined"
            type="button"
            class="btn btn-default btn-circle-md dark:text-white"
          >
            <a :href="'mailto:' + this.email">
              <i class="fa-solid fa-envelope"></i>
            </a>
          </button>
        </div>

        <div class="row mt-3 section-content">
          <!-- left content -->
          <div class="col-md-6 col-sm-12">
            <div class="row" v-if="this.description!='' && this.description!=undefined">
              {{ this.description }}
            </div>
            <div class="row mt-3" v-if="this.email!='' && this.email!=undefined">
              <div class="row title-content">Email</div>
              <div class="row description-content">
                <a :href="'mailto:' + this.email" style="color:white !important;">
                    {{this.email}}
                </a>
              </div>
            </div>
            <!-- <div class="row mt-3 justify-content-center"> -->
              <div class="col-md-4 col-sm-12 mt-1"  v-if="this.contactNumber!='' && this.contactNumber!=undefined">
                <div class="row">
                  <div class="row title-content">Mobile No.</div>
                  <div class="row description-content">
                    <a :href="'tel:' + this.contactNumber" style="color:white !important;">
                      {{this.contactNumber}}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 mt-3"  v-if="this.officeNumber!='' && this.officeNumber!=undefined">
                <div class="row">
                  <div class="row title-content">Office No.</div>
                  <div class="row description-content">
                    <a :href="'tel:' + this.officeNumber" style="color:white !important;">
                      {{this.officeNumber}}
                    </a>
                    </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 mt-3" v-if="this.faxNumber!='' && this.faxNumber!=undefined">
                <div class="row">
                  <div class="row title-content">Fax No.</div>
                  <div class="row description-content">
                    <a :href="'tel:' + this.faxNumber" style="color:white !important;">
                      {{this.faxNumber}}
                    </a>
                    </div>
                </div>
              </div>
            <!-- </div> -->
            <div class="row mt-4">
              <div class="row title-content" v-if= "counter>=1" >Connect with me on:</div>
              <div v-if = "this.counter <=5">
                  <div class="row mt-3 flex-nowrap justify-content-center">
                    <div class="col center-icon" v-if="this.whatsapp != '' &&  this.whatsapp!= undefined">
                        <button type="button" class="btn btn-default btn-circle btn-md dark:text-white" >
                          <a :href="'https://wa.me/'+this.whatsapp">
                            <i class="fa-brands fa-whatsapp"></i>
                          </a>
                        </button>
                      </div>
                      <div class="col center-icon" v-if="this.tele != '' &&  this.tele!= undefined">
                        <button type="button" class="btn btn-default btn-circle btn-md dark:text-white">
                          <a :href="'https://telegram.me/'+this.tele">
                            <i class="fa-brands fa-telegram"></i>
                          </a>
                        </button>
                      </div>
                      <div class="col center-icon" v-if="this.website != '' &&  this.website!= undefined">
                        <button type="button"  v-on:click="pushIcon('web')" class="btn btn-default btn-circle btn-md dark:text-white">
                          <i class="fa-solid fa-globe"></i>
                        </button>
                      </div>
                      <div class="col center-icon"  v-if="this.fb != '' &&  this.fb!= undefined" >
                        <button type="button" v-on:click="pushIcon('fb')" class="btn btn-default btn-circle btn-md dark:text-white">
                          <i class="fa-brands fa-facebook-f"></i>
                        </button>
                      </div>
                      <div class="col center-icon" v-if="this.linkedin != '' &&  this.linkedin!= undefined">
                        <button type="button" v-on:click="pushIcon('linkedin')" class="btn btn-default btn-circle btn-md dark:text-white">
                          <i class="fa-brands fa-linkedin-in"></i>
                        </button>
                      </div>
                      <div class="col center-icon" v-if="this.tiktok != '' &&  this.tiktok!= undefined" >
                        <button type="button" v-on:click="pushIcon('tiktok')" class="btn btn-default btn-circle btn-md dark:text-white">
                          <i class="fa-brands fa-tiktok"></i>
                        </button>
                      </div>
                      <div class="col center-icon" v-if="this.twitter != '' &&  this.twitter!= undefined">
                        <button type="button" v-on:click="pushIcon('twitter')" class="btn btn-default btn-circle btn-md dark:text-white">
                          <i class="fa-brands fa-twitter"></i>
                        </button>
                      </div>
                      <div class="col center-icon" v-if="this.insta != '' &&  this.insta!= undefined">
                        <button type="button" v-on:click="pushIcon('insta')" class="btn btn-default btn-circle btn-md dark:text-white">
                          <i class="fa-brands fa-square-instagram"></i>
                        </button>
                      </div>
                      <div class="col center-icon" v-if="this.snapchat != '' &&  this.snapchat!= undefined">
                        <button type="button"  v-on:click="pushIcon('snapchat')" class="btn btn-default btn-circle btn-md dark:text-white">
                          <i class="fa-brands fa-snapchat"></i>
                        </button>
                      </div>
                      <div class="col center-icon" v-if="this.youtube != '' &&  this.youtube!= undefined">
                        <button type="button" v-on:click="pushIcon('youtube')"  class="btn btn-default btn-circle btn-md dark:text-white">
                          <i class="fa-brands fa-youtube"></i>
                        </button>
                      </div>
                  </div>
                    
              </div>
              <div v-else>
                <!-- iv v-else> -->
                <div class="profile-contact-links" >
                  <div v-for="social in socialArr.slice(0,5)" :key="social">
                    
                    <div v-if="social=='fb'" class="profile-contact-link" >
                      <a v-on:click="pushIcon('fb')">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='insta'" class="profile-contact-link">
                      <a v-on:click="pushIcon('insta')">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div
                      v-if="social=='linkedin'"
                      class="profile-contact-link"
                    >
                      <a v-on:click="pushIcon('linkedin')">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='twitter'" class="profile-contact-link">
                      <a v-on:click="pushIcon('twitter')">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='web'" class="profile-contact-link">
                      <a v-on:click="pushIcon('web')">
                        <i class="fa fa-globe" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='youtube'" class="profile-contact-link">
                      <a v-on:click="pushIcon('youtube')">
                        <i class="fa fa-youtube" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='snapchat'" class="profile-contact-link">
                      <a v-on:click="pushIcon('snapchat')">
                        <i class="fa fa-snapchat" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='tiktok'" class="profile-contact-link">
                      <a v-on:click="pushIcon('tiktok')">
                        <i class="fab fa-tiktok" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='whatsapp'" class="profile-contact-link">
                      <a :href="'https://wa.me/'+this.whatsapp">
                        <i class="fa fa-whatsapp" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='tele'" class="profile-contact-link">
                      <a :href="'https://telegram.me/'+this.tele">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="profile-contact-links" style="margin-top:20px">
                  <div v-for="social in socialArr.slice(5)"  :key="social"> 
                    
                    <div v-if="social=='fb'" class="profile-contact-link" >
                      <a v-on:click="pushIcon('fb')">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='insta'" class="profile-contact-link">
                      <a v-on:click="pushIcon('insta')">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div
                      v-if="social=='linkedin'"
                      class="profile-contact-link"
                    >
                      <a v-on:click="pushIcon('linkedin')">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='twitter'" class="profile-contact-link">
                      <a v-on:click="pushIcon('twitter')">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='web'" class="profile-contact-link">
                      <a v-on:click="pushIcon('web')">
                        <i class="fa fa-globe" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='youtube'" class="profile-contact-link">
                      <a v-on:click="pushIcon('youtube')">
                        <i class="fa fa-youtube" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='snapchat'" class="profile-contact-link">
                      <a v-on:click="pushIcon('snapchat')">
                        <i class="fa fa-snapchat" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='tiktok'" class="profile-contact-link">
                      <a v-on:click="pushIcon('tiktok')">
                        <i class="fab fa-tiktok" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='whatsapp'" class="profile-contact-link">
                      <a :href="'https://wa.me/'+this.whatsapp">
                        <i class="fa fa-whatsapp" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="social=='tele'" class="profile-contact-link">
                      <a :href="'https://telegram.me/'+this.tele">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- right content -->
          <div class="col-md-6 col-sm-12">
            <div class="row"></div>
            <div class="row title-content mt-3">Address</div>
            <div class="row description-content" >
              <a :href="'https://www.google.com/maps/place/' + this.address "  target="_blank" style="color:white !important">{{ this.address }}</a
                      >
            </div>
            <div class="row mt-3">
                <div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31909.395481538835!2d103.89857477910157!3d1.3720270000000037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17f3a9e4d013%3A0x23e64849c0cf17c2!2sHydroflux%20Singapore%20Hot%2FCold%20Tankless%20Water%20Dispenser%20%7C%20Water%20Purifier%20%7C%20Alkaline%20Antioxidant%20Water!5e0!3m2!1sen!2ssg!4v1677432241526!5m2!1sen!2ssg"
                    width="100%"
                    height="300"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import QRious from 'qrious'
export default {

  props:{
        companyNameComponent: String,
        contactNumberComponent: String,
        emailComponent: String,
        fbComponent: String,
        instaComponent: String,
        linkedinComponent: String,
        nameComponent: String,
        profileImgComponent: String,
        roleComponent: String,
        twitterComponent: String,
        websiteComponent: String,
        addressComponent: String,
        officeNumberComponent: String,
        faxNumberComponent: String,
        descriptionComponent: String,
        youtubeComponent: String,
        tiktokComponent: String,
        whatsappComponent: String,
        teleComponent: String,
        snapchatComponent: String,
        picComponent: String,
        socialArrComponent: Array,
        counterComponent: Number,
        },
    
    data() {
        return {
          companyName: this.companyNameComponent,
          contactNumber: this.contactNumberComponent,
          email: this.emailComponent,
          fb: this.fbComponent,
          insta: this.instaComponent,
          linkedin: this.linkedinComponent,
          name: this.nameComponent,
          profileImg: this.profileImgComponent,
          role: this.roleComponent,
          twitter: this.twitterComponent,
          website: this.websiteComponent,
          address: this.addressComponent,
          officeNumber:this.officeNumberComponent,
          faxNumber:this.faxNumberComponent,
          description:this.descriptionComponent,
          youtube:this.youtubeComponent,
          tiktok:this.tiktokComponent,
          whatsapp:this.whatsappComponent,
          tele:this.teleComponent,
          snapchat:this.snapchatComponent,
          pic:this.pic,
          socialArr:this.socialArrComponent,
          counter:this.counterComponent,
          showSplashScreen: true,
          showModal:false,
          

        };
    },
        

  methods: {
     async shareLink(){
      if (navigator.share) {
          try {
              const title = "Add Contact";
              const text = "Hey, let's connect! Please add me to your contacts using this link:";
              const url = window.location.href;

              await navigator.share({
                  title: title,
                  text: text,
                  url: url
              });
              console.log("Content shared successfully.");
          } catch (error) {
              console.error("Error sharing content:", error);
          }
      } else {
          // Fallback for browsers that do not support the Web Share API
          console.log("Web Share API is not supported in this browser.");
      }
    },
    openModal(){
        this.showModal = true;
        this.$nextTick(() => {
            new QRious({
              element: this.$refs.qrcodeImg,
              value: window.location.href,
              size: 300,
              foreground: "#FFFFFF",
              background: "#212529"
            });
        })
   
      },
    pushIcon(icon) {
        if (icon == "fb") {
            var urlfb = this.fb;
            if (!urlfb.match(/^https?:\/\//i)) {
            urlfb = "https://" + urlfb;
            }
            window.open(urlfb, "_blank");
        } else if (icon == "twitter") {
            var urltwitter = this.twitter;
            if (!urltwitter.match(/^https?:\/\//i)) {
            urltwitter = "https://" + urltwitter;
            }
            window.open(urltwitter, "_blank");
        } else if (icon == "insta") {
            var urlinsta = this.insta;
            if (!urlinsta.match(/^https?:\/\//i)) {
            urlinsta = "https://" + urlinsta;
            }
            window.open(urlinsta, "_blank");
        } else if (icon == "linkedin") {
            var urllinkedin = this.linkedin;
            if (!urllinkedin.match(/^https?:\/\//i)) {
            urllinkedin = "https://" + urllinkedin;
            }
            window.open(urllinkedin, "_blank");
        } else if(icon == "web"){
            var urlwebsite = this.website;
            if (!urlwebsite.match(/^https?:\/\//i)) {
            urlwebsite = "https://" + urlwebsite;
            }
            window.open(urlwebsite, "_blank");
        } else if(icon == "snapchat"){
            var snapchat = this.snapchat;
            if (!snapchat.match(/^https?:\/\//i)) {
            snapchat = "https://" + snapchat;
            }
            window.open(snapchat, "_blank");
        } else if(icon == "tiktok"){
            var tiktok = this.tiktok;
            if (!tiktok.match(/^https?:\/\//i)) {
            tiktok = "https://" + tiktok;
            }
            window.open(tiktok, "_blank");
        }else if(icon == "youtube"){
            var youtube = this.youtube;
            if (!youtube.match(/^https?:\/\//i)) {
            youtube = "https://" + youtube;
            }
            window.open(youtube, "_blank");
        }
        
        },
       
        async saveContact() {
        
            const toDataURL = url => fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
                }))
            
            await toDataURL(this.profileImg)
                .then(dataUrl => {
                var pic = dataUrl
                var strImage = pic.replace(/^data:image\/[a-z]+;base64,/, "");

            //     var urlwebsite = this.website;
            // if (!urlwebsite.match(/^https?:\/\//i)) {
            //     urlwebsite = "https://" + urlwebsite;
            // }
            var vCardsJS = require("vcards-js");
            var vCard = vCardsJS();
            vCard.lastName = this.name;
            vCard.organization = this.companyName;
            vCard.cellPhone = this.contactNumber;
            vCard.workPhone = this.officeNumber;
            vCard.workFax = this.faxNumber;
            vCard.title = this.role;
            vCard.email = this.email;
            vCard.url = window.location.href;
            vCard.photo.embedFromString(strImage, 'image/png')
              // vCard.photo.embedFromString(getBase64Img,'image/jpeg');
              // if (urlwebsite != "https://"){
              //     vCard.url = urlwebsite
              // }
              vCard.note = this.description
              var vCardString = vCard.getFormattedString();
              var url =
                  "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardString);
              document.location.href = url;

                  })

              
            
            
            },
  },
 mounted(){
    setTimeout(() => {
      this.showSplashScreen = false;
    }, 3000);
  },
  watch: {
    companyNameComponent: function (newValue) {
          this.companyName = newValue;
      },
      contactNumberComponent: function (newValue) {
          this.contactNumber = newValue;
      },
      emailComponent: function (newValue) {
          this.email = newValue;
      },
        fbComponent: function (newValue) {
          this.fb = newValue;
      },
        instaComponent: function (newValue) {
          this.insta = newValue;
      },
        linkedinComponent: function (newValue) {
          this.linkedin = newValue;
      },
        nameComponent: function (newValue) {
          this.name = newValue;
      },
        profileImgComponent: function (newValue) {
          this.profileImg = newValue;
      },
        roleComponent: function (newValue) {
          this.role = newValue;
      },
        twitterComponent: function (newValue) {
          this.twitter = newValue;
      },
        websiteComponent: function (newValue) {
          this.website = newValue;
      },
        addressComponent: function (newValue) {
          this.address = newValue;
      },
        officeNumberComponent: function (newValue) {
          this.officeNumber = newValue;
      },
        faxNumberComponent: function (newValue) {
          this.faxNumber = newValue;
      },
        descriptionComponent: function (newValue) {
          this.description = newValue;
      },
        youtubeComponent: function (newValue) {
          this.youtube = newValue;
      },
        tiktokComponent: function (newValue) {
          this.tiktok = newValue;
      },
        whatsappComponent: function (newValue) {
          this.whatsapp = newValue;
      },
        teleComponent: function (newValue) {
          this.tele = newValue;
      },
        snapchatComponent: function (newValue) {
          this.snapchat = newValue;
      },
        picComponent: function (newValue) {
          this.pic = newValue;
      },
        socialArrComponent: function (newValue) {
          this.socialArr = newValue;
      },
        counterComponent: function (newValue) {
          this.counter = newValue;
      },
  },
  computed:{
    firstFiveSocials() {
      return this.socialArr.slice(0, 5)
    },
    remainingSocials() {
      return this.socialArr.slice(5)
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) { 
  .center-icon {
    text-align: center;
  }
  
}
@media (max-width: 768px){
  .card-img-top {
      height: 200px !important; 
      width: 100%;
      background-size: 100% 100%;
    }
}
body  {
  padding-bottom: 50px;
  
}
.col{
  background: #212529;
  color: white;
}
.card-img-top {
  /* height: 200px; */
  width: 100%;
  /* height: 400px;
  background-size: 100% 100%; */
  object-fit: cover;
  
}

.card-no-border .card {
  border-color: #d7dfe3;
  margin-bottom: 30px;
  /* -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05); */
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.pro-img {
  margin-top: -80px;
  margin-bottom: 20px;
}
.dark\:text-white {
  color: #212529 !important;
  background-color: white;
}
.dark\:border-dark img {
  border: 5px solid #212529 !important;
}

.little-profile .pro-img img {
  width: 150px;
  height: 150px;
  /* -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); */
  border-radius: 100%;
  /* border: 5px solid #ffffff; */
  /* background-color: #ffffff; */
}
.title-role {
  /* font-size: 20px; */
  color: #abd1e3;
}
.title-content {
  color: #abd1e3;
}
.btn-circle {
  width: 40px;
  height: 40px;
  padding: 6px 0px;
  border-radius: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 1.42857;
}
.btn-circle-md {
  width: 50px;
  height: 50px;
  padding: 6px 0px;
  border-radius: 100%;
  text-align: center;
  /* font-size: 20px; */
  line-height: 1.42857;
}
.btn-default {
  border: 1px solid grey;
}
.section-content {
  margin-left:5%;
  margin-right:5%
}
a {
  text-decoration: none !important;
  color: #212529 !important;

  }

.profile-contact {
  margin-top: 35px;
}

.profile-contact-links {
  display: flex;
  justify-content: space-between;
  /* padding: 0 0; */
}

.profile-contact-link {
  /* border-radius: 30px;
  padding: 10px 15px; */
  background: white;
  /* animation: name-of-animation 5s infinite; */
  margin-top: 10px;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  padding: 12px 2px;
  border-radius: 100%;
  text-align: center;
  /* line-height: 1.42857; */

}

.profile-contact-link {
  cursor: pointer;
}
.splash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

.splash-screen {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .splash-container {
    flex-direction: column;
  }
}

.icon-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  color: black !important;
  padding: 0.5rem;
}

.icon-button i {
  display: block;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}
  .popup .popup-inner {
    background: #212529;
    padding: 32px;
}
</style>
