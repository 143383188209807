<template>
  <div>
    <div v-if="showSplashScreen">
      <div class="splash-container">

        <img class="splash-screen" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/SplashScreen%2FCarquotee.gif?alt=media&token=f709bdf0-0ea3-4fe6-a004-5e0934340d31" />
          <!-- Your browser does not support the video tag. -->
        
      </div>
    </div>
    <div v-else>

      <div class="modal" :style="{ display: modalVisible ? 'flex' : 'none', alignItems: 'center', overflow: 'hidden' }">
        <div class="modal-content" style="background-color: #343434;">
          <span class="close" style="text-align: right;" @click="hideQRCode()">&times;</span>
          <div style="text-align: center;">
            <!-- QR Code image will be displayed here -->
            <canvas ref="qrcodeImg"></canvas>
          </div>

        </div>
      </div>
      <div class="backG" :style="backgroundImageFunction">
        <header class="headerSec">
          <section style="padding-top:5%">
            <div class="coverpic" :style="coverPhoto">
              <div class="cover">
                <div class="profile">
                  <img class="proPic" :src="profileImg" alt="" />
                  <!-- ../../public/images/profile_pic.png -->
                </div>
              </div>
            </div>
          </section>
        </header>
        <main class="profContainer">
          <!-- profile -->
          <section class="profSection">
            <div>
              <div class="profDiv">
                <div class="name">
                  <div class=" profName aboveDescription" style="display: flex; align-items: center;">
                    <h3 v-if="this.name != '' && this.name != undefined">{{this.name}}
                    </h3>
                    <i class="fa-solid fa-qrcode itemnode" @click="showQRCode()" style="margin-left: 10px;"></i>
                    <i class="fa-solid fa-share-nodes itemnode" @click="shareLink" style="margin-left: 10px;"></i>
                  </div>



                  <p class="proDetails" v-if="role != '' && role != undefined">{{this.role}}
                  </p>
                  <p class="profLink" v-if="this.companyName != '' && this.companyName != undefined">{{this.companyName}}
                  </p>



                </div>
                <p class="profDescription"
                  v-if="description != '' && description != undefined ">{{this.description}}
                </p>
              </div>
              <!-- buttons -->
              <div class="contactContainer ">
                <div v-if="this.email != '' && this.email != undefined ">
                  <p class="detailTag" >Email</p>
                  <a :href="'mailto:' + this.email"><p class="detailInfo" >{{this.email}}</p></a>
                </div>
                <div v-if="this.contactNumber != '' && this.contactNumber != undefined ">
                  <p class="detailTag">Mobile No.</p>
                  <a :href="'tel:' + this.contactNumber"><p class="detailInfo"
                    >{{this.contactNumber}}</p></a>
                </div>
                <div v-if="this.officeNumber != '' && this.officeNumber != undefined ">
                  <p class="detailTag">Office No.</p>
                  <a :href="'tel:' + this.officeNumber">
                    <p class="detailInfo">{{this.officeNumber}}</p>
                  </a>
                </div>
                <div v-if="this.faxNumber != '' && this.faxNumber != undefined ">
                  <p class="detailTag">Fax No.</p>
                  <p class="detailInfo">{{this.faxNumber}}</p>
                </div>
                <!-- contact info -->
                <div>
                  <p class="detailTag">Connect with me on</p>
                  
                      <div class="social-buttons row">
                      <div v-if="this.fb != '' && this.fb != undefined" class="profile-contact-link col-2" >
                        <a v-on:click="pushIcon('fb')">
                          <i class="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.insta != '' && this.insta != undefined" class="profile-contact-link col-2">
                        <a v-on:click="pushIcon('insta')">
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.linkedin != '' && this.linkedin != undefined" class="profile-contact-link col-2">
                        <a v-on:click="pushIcon('linkedin')">
                          <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.twitter != '' && this.twitter != undefined" class="profile-contact-link col-2">
                        <a v-on:click="pushIcon('twitter')">
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.website != '' && this.website != undefined" class="profile-contact-link col-2">
                        <a v-on:click="pushIcon('web')">
                          <i class="fa fa-globe" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div v-if="this.youtube != '' && this.youtube != undefined" class="profile-contact-link col-2">
                      <a v-on:click="pushIcon('youtube')">
                        <i class="fa fa-youtube" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="this.snapchat != '' && this.snapchat != undefined"  class="profile-contact-link col-2">
                      <a v-on:click="pushIcon('snapchat')">
                        <i class="fa fa-snapchat" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="this.tiktok != '' && this.tiktok != undefined" class="profile-contact-link">
                      <a v-on:click="pushIcon('tiktok')">
                        <i class="fab fa-tiktok" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="this.whatsapp != '' && this.whatsapp != undefined" class="profile-contact-link" >
                      <a :href="'https://wa.me/'+this.whatsapp" style="text-decoration: none; color:black;">
                        <i class="fa fa-whatsapp" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="this.tele != '' && this.tele != undefined" class="profile-contact-link" >
                      <a :href="'https://telegram.me/'+this.tele" style="text-decoration: none; color:black;">
                        <i class="fa fa-telegram" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div v-if="this.fbrating != '' && this.fbrating != undefined" class="profile-contact-link col-2">
                      <a v-on:click="pushIcon('fbrating')">
                         <img src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Ffacebook-reviews.png?alt=media&token=5ee43db1-79ac-4546-bb07-41a7e952ed67" class="icon-img">
                      </a>
                    </div>
                    <div v-if="this.googleRating != '' && this.googleRating != undefined" class="profile-contact-link col-2">
                      <a v-on:click="pushIcon('googlerating')">
                         <img src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2FGoogle-Review.png?alt=media&token=b0315b03-f700-4079-b724-1d6167bcaf03" class="icon-img">
                      </a>
                    </div>
                      

                  </div>
                </div>
                <!-- address location -->
                <div>
                  <p class="detailTag">Address</p>
                  <p class="detailInfo" v-if="this.address != '' && this.address != undefined ">
                    {{this.address}}
                  </p>
                </div>
                <div>
               
                
              </div>
            </div>
            <div class="map-container " style="margin-top:-15px">
                  <div class="iframe-container">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.738497009224!2d103.89694954554561!3d1.3330523951951534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17e4cda3366d%3A0xcb221c39abd013c4!2sCarQuotes%20Singapore!5e0!3m2!1sen!2ssg!4v1717945658881!5m2!1sen!2ssg"
                      width="100%"
                      height="300"
                      style="border: 0"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div></div></div>
            <div class="profile-detail contactContainer" v-if="this.galleryImages != undefined && this.galleryImages.length != 0">
               <div class="profile-detail-title detailTag">
                  <span>Image Gallery</span>
                </div>
               <div class="swiper-container"  ref="swiperContainer">
                      <div class="swiper-wrapper">
                        <div v-for="(value, key) in galleryImages" :key="key" class="swiper-slide">
                          
                          <img class="card-img-left" style="width: auto; height: 150px; align-items: center;" :src="value" />
                        </div>
                      </div>

                      <div class="swiper-pagination" ></div>
                      <div class="swiper-button-next"></div>
                      <div class="swiper-button-prev"></div>
                    </div>
                    
              </div>
          </section>
          
          <!-- button -->
          <section class="btn">
            <button class="btn-pri" role="button" @click="saveContact()">Add to Contact</button>
          </section>
        </main>
      </div>
    </div>
  </div>
</template>


<script>
import QRious from 'qrious';
import Ripple from 'vue3-whr-ripple-directive';
import Swiper, { Navigation, Pagination,EffectCoverflow } from 'swiper';
import 'swiper/swiper-bundle.css'

export default {
  directives: {
    ripple: Ripple,
  },
  props: {
    companyNameComponent: String,
    contactNumberComponent: String,
    emailComponent: String,
    fbComponent: String,
    instaComponent: String,
    linkedinComponent: String,
    nameComponent: String,
    profileImgComponent: String,
    coverImgComponent: String,
    splashVideoComponent: String,
    backgroundImageComponent: String,
    roleComponent: String,
    twitterComponent: String,
    websiteComponent: String,
    addressComponent: String,
    officeNumberComponent: String,
    faxNumberComponent: String,
    descriptionComponent: String,
    youtubeComponent: String,
    tiktokComponent: String,
    googleRatingComponent: String,
    fbRatingComponent: String,
    whatsappComponent: String,
    teleComponent: String,
    snapchatComponent: String,
    picComponent: String,
    socialArrComponent: Array,
    counterComponent: Number,
    addtionalInfoComponent: String,
    pdfFileComponent: String,
    pdfNameComponent: String,
    galleryImagesComponent: Array

  },

  data() {
    return {
      center: { lat: 51.093048, lng: 6.842120 },
      markers: [
        {
          position: {
            lat: 51.093048, lng: 6.842120
          },
        }
        , // Along list of clusters
      ],

      companyName: this.companyNameComponent,
      contactNumber: this.contactNumberComponent,
      email: this.emailComponent,
      fb: this.fbComponent,
      insta: this.instaComponent,
      linkedin: this.linkedinComponent,
      name: this.nameComponent,
      profileImg: this.profileImgComponent,
      coverImg: this.coverImgComponent,
      splashVideo: this.splashVideoComponent,
      backgroundImg: this.backgroundImageComponent,
      role: this.roleComponent,
      twitter: this.twitterComponent,
      website: this.websiteComponent,
      address: this.addressComponent,
      officeNumber: this.officeNumberComponent,
      faxNumber: this.faxNumberComponent,
      description: this.descriptionComponent,
      youtube: this.youtubeComponent,
      tiktok: this.tiktokComponent,
      googleRating: this.googleRatingComponent,
      fbrating: this.fbRatingComponent,
      whatsapp: this.whatsappComponent,
      tele: this.teleComponent,
      snapchat: this.snapchatComponent,
      pic: this.pic,
      socialArr: this.socialArrComponent,
      counter: this.counterComponent,
      showSplashScreen: true,
      modalVisible: false,
      qrCodeUrl: "",
      additionalInfo: this.addtionalInfoComponent,
      pdfFile: this.pdfFileComponent,
      pdfName: this.pdfNameComponent,
      copied: false,
      galleryImages:this.galleryImagesComponent

    };
  },

  methods: {
    phoneFunction() { window.location.href = 'tel:' + this.contactNumber },
    emailFunction() { window.location.href = 'mailto:' + this.email },
    initializeSwiper() {
      //Swiper
      Swiper.use([Navigation, Pagination, EffectCoverflow]);
      
      // this.$nextTick(() => {
        this.swiper=new Swiper('.swiper-container', {
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          initialSlide: 0,
          slideToClickedSlide: true,
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
          },
          breakpoints: {
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }
        });
      },
    copyWebsite() {
      if (this.website) {
        navigator.clipboard.writeText(this.website)
          .then(() => {
            this.copied = true;
            setTimeout(() => {
              this.copied = false; // Reset icon and tooltip after some time
            }, 2000); // Reset after 2 seconds
          })
          .catch(err => {
            console.error('Failed to copy:', err);
          });
      }
    },
    async shareLink() {
      if (navigator.share) {
        try {
          const title = "Add Contact";
          const text = "Hey, let's connect! Please add me to your contacts using this link:";
          const url = window.location.href;

          await navigator.share({
            title: title,
            text: text,
            url: url
          });
          console.log("Content shared successfully.");
        } catch (error) {
          console.error("Error sharing content:", error);
        }
      } else {
        // Fallback for browsers that do not support the Web Share API
        console.log("Web Share API is not supported in this browser.");
      }
    },
    showQRCode() {
      console.log("Clciekd")

      this.$nextTick(() => {

        new QRious({
          element: this.$refs.qrcodeImg,
          value: window.location.href,
          size: 250
        });


      })
      this.modalVisible = true;
    },
    hideQRCode() {
      this.modalVisible = false;
    },

    pushIcon(icon) {
      if (icon == "fb") {
        var urlfb = this.fb;
        if (!urlfb.match(/^https?:\/\//i)) {
          urlfb = "https://" + urlfb;
        }
        window.open(urlfb, "_blank");
      } else if (icon == "twitter") {
        var urltwitter = this.twitter;
        if (!urltwitter.match(/^https?:\/\//i)) {
          urltwitter = "https://" + urltwitter;
        }
        window.open(urltwitter, "_blank");
      } else if (icon == "insta") {
        var urlinsta = this.insta;
        if (!urlinsta.match(/^https?:\/\//i)) {
          urlinsta = "https://" + urlinsta;
        }
        window.open(urlinsta, "_blank");
      } else if (icon == "linkedin") {
        var urllinkedin = this.linkedin;
        if (!urllinkedin.match(/^https?:\/\//i)) {
          urllinkedin = "https://" + urllinkedin;
        }
        window.open(urllinkedin, "_blank");
      } else if (icon == "web") {
        var urlwebsite = this.website;
        if (!urlwebsite.match(/^https?:\/\//i)) {
          urlwebsite = "https://" + urlwebsite;
        }
        window.open(urlwebsite, "_blank");
      } else if (icon == "snapchat") {
        var snapchat = this.snapchat;
        if (!snapchat.match(/^https?:\/\//i)) {
          snapchat = "https://" + snapchat;
        }
        window.open(snapchat, "_blank");
      } else if (icon == "tiktok") {
        var tiktok = this.tiktok;
        if (!tiktok.match(/^https?:\/\//i)) {
          tiktok = "https://" + tiktok;
        }
        window.open(tiktok, "_blank");
      } else if (icon == "youtube") {
        var youtube = this.youtube;
        if (!youtube.match(/^https?:\/\//i)) {
          youtube = "https://" + youtube;
        }
        window.open(youtube, "_blank");
      }
      else if (icon == "googlerating") {
        var googlereview = this.googleRating;
        if (!googlereview.match(/^https?:\/\//i)) {
          googlereview = "https://" + googlereview;
        }
        window.open(googlereview, "_blank");
      }
      else if (icon == "fbrating") {
        var fbrating = this.fbrating;
        if (!fbrating.match(/^https?:\/\//i)) {
          fbrating = "https://" + fbrating;
        }
        window.open(fbrating, "_blank");
      }

    },

    async saveContact() {

      const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        }))

      await toDataURL(this.profileImg)
        .then(dataUrl => {
          var pic = dataUrl
          var strImage = pic.replace(/^data:image\/[a-z]+;base64,/, "");

          var urlwebsite = this.website;
          if (!urlwebsite.match(/^https?:\/\//i)) {
            urlwebsite = "https://" + urlwebsite;
          }
          var vCardsJS = require("vcards-js");
          var vCard = vCardsJS();
          vCard.lastName = this.name;
          vCard.organization = this.companyName;
          vCard.cellPhone = this.contactNumber;
          vCard.workPhone = this.officeNumber;
          vCard.workFax = this.faxNumber;
          vCard.title = this.role;
          vCard.email = this.email;

          vCard.photo.embedFromString(strImage, 'image/png')
          // vCard.photo.embedFromString(getBase64Img,'image/jpeg');
          if (urlwebsite != "https://") {
            vCard.url = urlwebsite
          }
          vCard.note = this.description
          var vCardString = vCard.getFormattedString();
          var url =
            "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardString);
          document.location.href = url;

        })




    },

    downloadPDF() {
      // this.pdfFile.substring(this.pdfFile.lastIndexOf("/") + 1).split("?")[0];
      var self = this;
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function () {
        console.log(self.pdfName)
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = self.pdfName
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      };
      xhr.open('GET', self.pdfFile);
      xhr.send();
    },

  },
  mounted() {
    
    setTimeout(() => {
      this.showSplashScreen = false;
      this.$nextTick(() => {
            this.initializeSwiper();
          })
    }, 6000);
    
  },

  watch: {

    companyNameComponent: function (newValue) {
      this.companyName = newValue;
    },
    contactNumberComponent: function (newValue) {
      this.contactNumber = newValue;
    },
    emailComponent: function (newValue) {
      this.email = newValue;
    },
    fbComponent: function (newValue) {
      this.fb = newValue;
    },
    instaComponent: function (newValue) {
      this.insta = newValue;
    },
    linkedinComponent: function (newValue) {
      this.linkedin = newValue;
    },
    nameComponent: function (newValue) {
      this.name = newValue;
    },
    profileImgComponent: function (newValue) {
      this.profileImg = newValue;
    },
    coverImgComponent: function (newValue) {
      this.coverImg = newValue;
    },
    splashVideoComponent: function (newValue) {
      this.splashVideo = newValue;
    },
    backgroundImageComponent: function (newValue) {
      this.backgroundImg = newValue;
    },

    roleComponent: function (newValue) {
      this.role = newValue;
    },
    twitterComponent: function (newValue) {
      this.twitter = newValue;
    },
    websiteComponent: function (newValue) {
      this.website = newValue;
    },
    addressComponent: function (newValue) {
      this.address = newValue;
    },
    officeNumberComponent: function (newValue) {
      this.officeNumber = newValue;
    },
    faxNumberComponent: function (newValue) {
      this.faxNumber = newValue;
    },
    descriptionComponent: function (newValue) {
      this.description = newValue;
    },
    youtubeComponent: function (newValue) {
      this.youtube = newValue;
    },
    tiktokComponent: function (newValue) {
      this.tiktok = newValue;
    },

    googleRatingComponent: function (newValue) {
      this.googleRating = newValue;
    },
    fbRatingComponent: function (newValue) {
      this.fbRating = newValue;
    },
    whatsappComponent: function (newValue) {
      this.whatsapp = newValue;
    },
    teleComponent: function (newValue) {
      this.tele = newValue;
    },
    snapchatComponent: function (newValue) {
      this.snapchat = newValue;
    },
    picComponent: function (newValue) {
      this.pic = newValue;
    },
    socialArrComponent: function (newValue) {
      this.socialArr = newValue;
    },
    counterComponent: function (newValue) {
      this.counter = newValue;
    },
    additionalInfoComponents: function (newValue) {
      this.additionalInfo = newValue;
    },
    pdfFileComponent: function (newValue) {
      this.pdfFile = newValue;
    },
    pdfNameComponent: function (newValue) {
      this.pdfName = newValue;
    },
    galleryImagesComponent: function (newValue) {
          this.galleryImages = newValue;
      },
  },

  computed: {
    coverPhoto() {

      return {
        backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/Banner%2FCarQuotes-Logo.png?alt=media&token=8a38e0ac-f319-41f6-9dad-b744b868c9e9")`,
        backgroundRepeat: 'no-repeat',

        backgroundSize: 'contain'
      }
    },
    backgroundImageFunction() {
      return {
        backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/Background%2FbackGround.jpeg?alt=media&token=8898f53a-5cc7-4882-8ffe-ff6092d0156e")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }
    },
    firstFiveSocials() {
      return this.socialArr.slice(0, 5)
    },
    remainingSocials() {
      return this.socialArr.slice(5)
    }
  }
}

</script>
<style scoped>
* {
  box-sizing: border-box;
  /* Box-sizing set to border-box for all elements */
}

body {

  margin: auto;
  /* Center align the body content */
  padding: 0;
  /* Remove default padding */
  max-width: auto;
  /* Set maximum width for content */
  background-color: #343434;
  /* Set background color for the body */
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 400px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.poppins-font {
  font-family: "Poppins-regular", sans-serif;
}




.backG {
  width: 100%;
  height: 100%;
  position: relative;
  /* background-image: url("../../public/images/backGround.jpeg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.headerSec {
  max-width: 100%;
  /* Make header section responsive */
  margin: auto;
  /* Center align the header section */
}

.coverpic {
  position: relative;
  width: 100%;
  /* Set width to 100% */
  height: 60vh;
  /* Set height to 70% of the viewport height */
}

.cover {
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Stretch the cover to fill its container */
  /* background-image: url("../../public/images/coverpic.jpg"); */
  /* Set cover image */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* Stretch the background image to cover the entire container */
}

.profile {
  z-index: 2;
  width: 100%;
  position: absolute;
  /* Position profile section absolutely */
  color: white;
  left: 50%;
  /* Align profile section to the center horizontally */
  bottom: -200px;
  /* Initially positioned below the container */
  transform: translate(-50%, -50%);
  text-align: center;
  overflow: hidden;
}

.proPic {
  /* Make the profile picture round */
  border: none;
  width: 13rem;
  overflow: hidden;
  border-radius: 50%;
}

/* profile */
.profContainer {
  margin-top: 100px;
}

.profSection {
  padding: 20px;
}

.profDiv {
  display: grid;
  justify-items: center;
}

.name {
  color: white;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 40px;
}

.proName {
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.proDetails {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.quotes {
  color: #3a8f60;
}

.profDescription {
  width: 70%;
  color: white;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.contactContainer {
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  color: white;
  display: grid;
  gap: 20px;
  padding: 20px;
}

.detailTag {
  margin: 0;
  padding: 0;
  color: #3a8f60;
  font-size: 16px;
  font-weight: 400;
}

.detailInfo {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 15px;
  font-weight: 400;
}

/* social link */
.social-buttons {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.social-icon {
  margin-bottom: 15px;
  box-sizing: border-box;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 1px;
  padding-top: 11px;
}

/* Facebook Button Styling */
.icon {
  font-size: 22px;
  padding-top: 9px;

  background-color: #ffffff;
  color: black;
}

.circular-icon {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #ffffff;
}

.facebook-icon {
  width: 135%;
  height: 80%;
  margin-top: 5px;
  margin-left: -15%;
}

.google-icon {
  width: 100%;
  height: 60%;
  margin-top: 5px;
}

/* map */
#map {
  height: 250px;
  width: 100%;
  margin-top: 16px;
}

/* button */
.btn {
  display: grid;
  justify-content: center;
  padding-bottom: 20px;
}

.btn-pri {
  background-color: #fcffff;
  border-radius: 100px;

  color: #3a8f60;
  cursor: pointer;
  display: inline-block;

  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 20px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-pri:hover {
  transform: scale(1.05) rotate(-1deg);
}

/* sticky-button */
/* .footerBtn {
width: 100%;
} */
/* .sticky-button {
z-index: 5;

max-width: 1440px;
box-sizing: border-box;
display: inline-block;
position: fixed;
transform: translateX(-50%, -50%); 
bottom: 0; 
padding: 24px 30px;
font-size: 1.5em;
background-color: #c0b397;
border: 2px solid #c0b397;
color: white;
border: none;
border-top-left-radius: 18px;
border-top-right-radius: 18px;
cursor: pointer;
overflow: hidden;
transition: all 0.2s ease-in;
} */
.sticky-button .circle {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  background-image: radial-gradient(circle closest-side, white, #c1b398);
  width: 1px;
  height: 1px;
  z-index: 3;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

/* .sticky-button:hover {
transform: scale(1.01);
box-shadow: 0 6px 4px darkgray;
} */

/* @-webkit-keyframes ripple {
0% {
  transform: scale(0, 0);
  opacity: 0;
}

75% {
  transform: scale(450, 450);
  opacity: 0.75;
}

100% {
  transform: scale(700, 700);
  opacity: 0;
}
}

@keyframes ripple {
0% {
  transform: scale(0, 0);
  opacity: 0;
}

75% {
  transform: scale(450, 450);
  opacity: 0.75;
}

100% {
  transform: scale(700, 700);
  opacity: 0;
}
} */

/* For large devices (desktops and laptops) */
@media only screen and (min-width: 1200px) {
  .proPic {
    border: none;
    width: 13rem;
  }

  /* .splash-container {
    padding: 30px;
  } */

  .splash-screen {
    width: 100%;
    height: auto;
  }
}

/* For medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 1199px) {

  /* .splash-container {
    padding: 20px;
  } */

  .splash-screen {
    width: 100%;
    height: auto;
  }

  .profile {
    bottom: -160px;
    /* Initially positioned below the container */
  }

  .coverpic {
    height: 40vh;
  }

  .proPic {
    border: none;
    width: 10rem;
  }

  .name {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

/* For small devices (landscape phones) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contactContainer {
    justify-content: flex-start;
  }

  .coverpic {
    height: 35vh;
  }

  .profile {
    bottom: -150px;
    /* Initially positioned below the container */
  }

  .profContainer {
    margin-top: 50px;
  }

  .proPic {

    width: 9rem;
  }

  .chan {
    margin: 0;
  }

  .sticky-button {
    width: 100%;
  }
}

/* For extra small devices (portrait phones) */
@media only screen and (max-width: 575px) {
  /* .splash-container {
    padding: 10px;
  } */

  .splash-screen {
    width: 100%;
    height: auto;
  }



  .contactContainer {
    justify-content: flex-start;
  }

  .profDescription {
    width: 100%
  }

  .profile {
    bottom: -130px;
    /* Initially positioned below the container */
  }



  .backG {
    background-position: 40% center;
    background-size: 1200px 100%;
  }

  .coverpic {

    object-fit: cover;
    height: 23vh;
    /* Set height to 70% of the viewport height */
  }

  .proPic {
    border: none;
    width: 7.8rem;
  }

  .name {
    padding-top: 5px;
    padding-bottom: 12px;

    font-weight: 700;
    text-align: center;
  }

  .profName {
    gap: 12px;
  }

  .profContainer {
    margin-top: 50px;
  }

  .proftext {
    padding: 8px;
    font-size: 20px;
    font-weight: 600;
  }

  .profDescription {
    margin: 0;
    padding: 0px 10px;
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
  }

  .contactContainer {
    padding: 10px;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip::after {

  font-size: 12px;
  content: attr(data-tooltip);
  visibility: hidden;
  opacity: 0;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.3);
  ;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%;

  left: 50%;
  margin-left: -60px;

  opacity: 0.1;
  transition: opacity 0.3s;
  pointer-events: none;
}

.custom-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
}

.splash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #101010;
  z-index: 500;
}

.splash-screen {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.profLink {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #3a8f60;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.social-buttons {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  
  
}

.social-icon {
  margin-bottom: 15px;
  box-sizing: border-box;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 1px;
  padding-top: 11px;
}
.profile-contact-link {
  /* border-radius: 30px;
  padding: 10px 15px; */
  background: white;
  /* animation: name-of-animation 5s infinite; */
  margin-top: 10px;
  margin-right: 6px;
  margin-left: 6px;
  width: 45px;
  height: 45px;
  padding: 10px 5px;
  border-radius: 100%;
  text-align: center;
  /* line-height: 1.42857; */
  color:#343434;

}
.card-img-left {
    max-width: 100%;
    height: auto;
    display: block; /* Ensures the image takes up the whole line */
    margin-left: auto;
    margin-right: auto;
}

.swiper-container {
    width: 100%; /* Adjust the width to be 100% of the parent */
}
.icon-img{
  height: 15px;
  width: auto;
  padding-bottom: 2px;
}
.profile-detail{
  overflow-x: hidden;
}
a{
  text-decoration: none;
}



</style>