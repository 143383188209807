<template>
  <div v-if="showSplashScreen">
    <div class="splash-container">
      <img class="splash-screen"
        src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/SplashScreen%2FVAST_TEAM.gif?alt=media&token=43e96fcc-b70e-49c1-8609-dd1db932b91b"
        alt="Splash Screen" />
    </div>
  </div>
  <div v-else>

    <div class="modal" :style="{ display: modalVisible ? 'flex' : 'none', alignItems: 'center',  overflow: 'hidden' }">
      <div class="modal-content" style="background-color: #343434;">
        <span class="close" style="text-align: right;" @click="hideQRCode()">&times;</span>
        <div style="text-align: center;">
          <!-- QR Code image will be displayed here -->
          <canvas ref="qrcodeImg"></canvas>
        </div>

      </div>
    </div>
    <div id="MyAppDiv">
      <div class="inter-font bodyArea">
        <header class="headerSec">
          <section>
            <div class="coverpic">
              <div class="cover">
                <div class="profile">
                  <img class="proPic" :src="profileImg" alt="Profile picture" />
                </div>
              </div>
            </div>
          </section>
        </header>
        <main class="profContainer">
          <section class="profSection">
            <div>
              <div class="profDiv">


                <div class="name profName aboveDescription" >
                  <p class="chan miama ">{{ name }}</p>
                  <i class="fa-solid fa-qrcode itemnode" @click="showQRCode()"></i>
                  <i class="fa-solid fa-share-nodes itemnode" @click="shareLink"></i>
                </div>

                <!-- <div class="name profName">
                  <h3 class="chan miama " style="font-size:20px">{{ name }}</h3>
                  <i class="fa-solid fa-qrcode" @click="showQRCode()" style="font-size: 0.73em;"></i>
                  <i class="fa-solid fa-share-nodes" @click="shareLink" style="font-size: 0.73em;"></i>
                </div> -->
                <h3 class="proftext aboveDescription" style="  margin-top:-7px">
                  {{ companyName }}
                </h3>
                <h3 class="proftext aboveDescription" style=" margin-top:-15px">{{ role }}
                </h3>
                <!-- <div v-if="this.website != '' && this.website != undefined" style=" margin-top:-10px">
                  <a class="profLink aboveDescription" v-on:click="pushIcon('web')" >
                    <i class="fa-solid fa-link"></i> {{ website }}</a>
                </div> -->
                <div v-if="website" style="margin-top: -10px; position: relative;">
                   <a class="profLink aboveDescription" v-on:click="pushIcon('web')" style=""><i class="fa-solid fa-link"
                      style=" margin-top:-10px"></i> {{ website }}</a>
                </div>
                <p class="profDescription" style="white-space: pre-wrap; font-size:15px !important; margin-top:12px !important">
                  {{ description }}
                </p>
              </div>
              <div class="btnContainer" style="margin-left:10px !important;margin-right:10px !important;">
                <button v-if="this.contactNumber != '' && this.contactNumber != undefined " class="btnPhnNum" @click="phoneFunction" v-ripple>
                  <i class="fa-solid fa-phone-volume phone-icon"></i>
                  <span class="phn-nmb">{{ contactNumber }}</span>
                </button>
                
                <button v-if="this.email != '' && this.email != undefined" class="btnE-mail" @click="emailFunction" v-ripple>
                  <i class="fa-regular fa-envelope env-color"></i>
                  <span class="emailAddress" style="padding-left:2px">{{ email }}</span>
                </button>
              </div>
              <div class="downloadContainer">
                <div class="downloadLink" >
                  <div class="downloadDiv">
                    <h5 v-if="(this.pdfFile != '' && this.pdfFile!= undefined) || (this.additionalInfo != '' && this.additionalInfo!= undefined)" class="downloadText belowDescriptionHeader" style="font-weight: 700;">Accreditation</h5>
                    <a v-if="this.pdfFile != '' && this.pdfFile != undefined" @click="downloadPDF" target="_blank" download>
                      <i class="fa-regular fa-file-pdf belowDescriptionHeader" style="padding-bottom:5px"></i>
                    </a>                     
                  </div>
                  
                </div>
                
              </div>
              <hr v-if="(this.pdfFile != '' && this.pdfFile!= undefined) || (this.additionalInfo != '' && this.additionalInfo!= undefined)" style="color:white;margin-top:2px; margin-left:10px;margin-right:10px; margin-bottom:5px" />
            </div>
          </section>
          <section class="planning" v-if="this.additionalInfo != '' && this.additionalInfo!= undefined">
            <p class="planningtext belowDescriptionText " style="white-space: pre-wrap;">
              {{ additionalInfo }}
            </p>
          </section>
          <section class="socialContainer"
            :style="{ 'padding-bottom': dynamicPadding}">
            <h3 v-if=" !this.socialArr.length == 0" class="socialTitle belowDescriptionHeader">My Socials</h3>
            <hr v-if=" !this.socialArr.length == 0" class="separator" style="color:white !important;margin-top:3px; margin-bottom:5px "/>
            <div class="socialLink">
              <!-- Generate social media links dynamically -->
              <div class="item" v-if="this.fb != '' && this.fb != undefined">
                <img  v-on:click="pushIcon('fb')" title="Facebook" class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Ffb%20(2).png?alt=media&token=eaa31c6c-d66f-4741-8d6a-f12407ebaaac"/> 
              </div>
              <div class="item" v-if="this.insta != '' && this.insta != undefined">
                <img  v-on:click="pushIcon('insta')" title="Instagram" class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Finstagram%20(2).png?alt=media&token=caf38c44-5bb1-45f7-be18-7798e9ec769a"/>
              </div>
              <div class="item" v-if="this.linkedin != '' && this.linkedin != undefined">
                <img  v-on:click="pushIcon('linkedin')" title="LinkedIn" class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Flinkedin%20(2).png?alt=media&token=a9cdec13-2857-49c9-8a9e-b6da0c4376c7"/>
              </div>
              <div class="item" v-if="this.twitter != '' && this.twitter != undefined">
                <img  v-on:click="pushIcon('twitter')" title="Twitter" class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Fx%20(2).png?alt=media&token=10b61908-1cee-4ab4-903c-e805c7f7a55d"/>
              </div>
              <div class="item" v-if="this.youtube != '' && this.youtube != undefined">
                <img  v-on:click="pushIcon('youtube')" title="YouTube" class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Fyt.png?alt=media&token=41d3714b-203b-437a-a942-7c520db16f0f"/>
              </div>
              <div class="item" v-if="this.tiktok != '' && this.tiktok != undefined">
                <img  v-on:click="pushIcon('tiktok')" title="TikTok" class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Ftt.png?alt=media&token=67aecc90-30ad-4cd2-8bb5-3000393b35a4"/>
              </div>
              <div class="item" v-if="this.whatsapp != '' && this.whatsapp != undefined">
                <a :href="'https://wa.me/' + this.whatsapp" title="WhatsApp">
                  <img class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Fws.png?alt=media&token=d8673a10-cf75-4820-be80-446d0886c0e7"/>
                </a>
              </div>
              <div class="item" v-if="this.tele != '' && this.tele != undefined">
                <a :href="'https://telegram.me/' + this.tele" title="Telegram">
                <img class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Ftele.png?alt=media&token=3194d54c-8d36-413a-a74d-8e9a5d78b764"/>
                </a>
              </div>
              <div class="item" v-if="this.snapchat != '' && this.snapchat != undefined">
                <img  v-on:click="pushIcon('snapchat')" title="Snapchat" class="socialIcons" src="https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/assets%2Fsc.png?alt=media&token=2790ef7f-c88b-4207-b81c-1ba7e1644273"/>
              </div>
            </div>
          </section>
          <section class="addressContainer">
            <h4  v-if="this.address != '' && this.address != undefined" class="addressText belowDescriptionHeader">Address</h4>
            <hr  v-if="this.address != '' && this.address != undefined" style="color:white;margin-top:3px;margin-bottom:5px " />
            <a  v-if="this.address != '' && this.address != undefined" class="addressInfo belowDescriptionText " :href="'https://www.google.com/maps/place/' + this.address
    " target="_blank" style="text-decoration: none;">
              {{ address }}
            </a>

            <div v-if="this.galleryImages != undefined && this.galleryImages.length != 0" class="slider" style="padding-top:20px;">
              <h4   class="addressText belowDescriptionHeader">Your Highlights</h4>
               <hr  style="color:white;margin-top:3px;margin-bottom:5px " />
              <div class="slides" :style="transformStyle">
                <img v-for="(image, index) in this.galleryImages" :key="index" :src="image" :alt="'Image ' + (index + 1)" />
              </div>
              <button class="slider-button prev" @click="changeSlide(-1)">&#10094;</button>
              <button class="slider-button next" @click="changeSlide(1)">&#10095;</button>
            </div>
          </section>
      
            
          <section class="footerBtn" style="font-size: 12px">
            <button class="sticky-button" @click="saveContact" v-ripple
              style="font-weight:bold; margin-bottom:-20px !important;">
              <p>LET'S CONNECT!</p>
              <span></span>
            </button>
          </section>
        </main>
      </div>
    </div>
  </div>
</template>


<script>
import QRious from 'qrious';
// import Swiper, { Navigation, Pagination,EffectCoverflow } from 'swiper';
import Ripple from 'vue3-whr-ripple-directive';
import 'swiper/swiper-bundle.css'

export default {
  directives: {
    ripple: Ripple,
  },
  props: {
    companyNameComponent: String,
    contactNumberComponent: String,
    emailComponent: String,
    fbComponent: String,
    instaComponent: String,
    linkedinComponent: String,
    nameComponent: String,
    profileImgComponent: String,
    roleComponent: String,
    twitterComponent: String,
    websiteComponent: String,
    addressComponent: String,
    officeNumberComponent: String,
    faxNumberComponent: String,
    descriptionComponent: String,
    youtubeComponent: String,
    tiktokComponent: String,
    whatsappComponent: String,
    teleComponent: String,
    snapchatComponent: String,
    picComponent: String,
    socialArrComponent: Array,
    counterComponent: Number,
    addtionalInfoComponent: String,
    pdfFileComponent: String,
    pdfNameComponent: String,
    galleryImagesComponent: Array
  },

  data() {
    return {
      companyName: this.companyNameComponent,
      contactNumber: this.contactNumberComponent,
      email: this.emailComponent,
      fb: this.fbComponent,
      insta: this.instaComponent,
      linkedin: this.linkedinComponent,
      name: this.nameComponent,
      profileImg: this.profileImgComponent,
      role: this.roleComponent,
      twitter: this.twitterComponent,
      website: this.websiteComponent,
      address: this.addressComponent,
      officeNumber: this.officeNumberComponent,
      faxNumber: this.faxNumberComponent,
      description: this.descriptionComponent,
      youtube: this.youtubeComponent,
      tiktok: this.tiktokComponent,
      whatsapp: this.whatsappComponent,
      tele: this.teleComponent,
      snapchat: this.snapchatComponent,
      pic: this.pic,
      socialArr: this.socialArrComponent,
      counter: this.counterComponent,
      showSplashScreen: true,
      modalVisible: false,
      qrCodeUrl: "",
      additionalInfo: this.addtionalInfoComponent,
      pdfFile: this.pdfFileComponent,
      pdfName: this.pdfNameComponent,
      copied: false,
      galleryImages:this.galleryImagesComponent,
      currentIndex: 0
    };
  },


  methods: {
    phoneFunction(){window.location.href = 'tel:' + this.contactNumber},
    emailFunction(){window.location.href = 'mailto:' + this.email},
    changeSlide(direction) {
      this.currentIndex += direction;
      if (this.currentIndex < 0) {
        this.currentIndex = this.galleryImages.length - 1;
      } else if (this.currentIndex >= this.galleryImages.length) {
        this.currentIndex = 0;
      }
    }, 
    copyWebsite() {
      if (this.website) {
        navigator.clipboard.writeText(this.website)
          .then(() => {
            this.copied = true;
            setTimeout(() => {
              this.copied = false; // Reset icon and tooltip after some time
            }, 2000); // Reset after 2 seconds
          })
          .catch(err => {
            console.error('Failed to copy:', err);
          });
      }
    },
    async shareLink() {
      if (navigator.share) {
        try {
          const title = "Add Contact";
          const text = "Hey, let's connect! Please add me to your contacts using this link:";
          const url = window.location.href;

          await navigator.share({
            title: title,
            text: text,
            url: url
          });
          console.log("Content shared successfully.");
        } catch (error) {
          console.error("Error sharing content:", error);
        }
      } else {
        // Fallback for browsers that do not support the Web Share API
        console.log("Web Share API is not supported in this browser.");
      }
    },
    showQRCode() {
      console.log("Clciekd")

      this.$nextTick(() => {

        new QRious({
          element: this.$refs.qrcodeImg,
          value: window.location.href,
          size: 250
        });


      })
      this.modalVisible = true;
    },
    hideQRCode() {
      this.modalVisible = false;
    },

    pushIcon(icon) {
      if (icon == "fb") {
        var urlfb = this.fb;
        if (!urlfb.match(/^https?:\/\//i)) {
          urlfb = "https://" + urlfb;
        }
        window.open(urlfb, "_blank");
      } else if (icon == "twitter") {
        var urltwitter = this.twitter;
        if (!urltwitter.match(/^https?:\/\//i)) {
          urltwitter = "https://" + urltwitter;
        }
        window.open(urltwitter, "_blank");
      } else if (icon == "insta") {
        var urlinsta = this.insta;
        if (!urlinsta.match(/^https?:\/\//i)) {
          urlinsta = "https://" + urlinsta;
        }
        window.open(urlinsta, "_blank");
      } else if (icon == "linkedin") {
        var urllinkedin = this.linkedin;
        if (!urllinkedin.match(/^https?:\/\//i)) {
          urllinkedin = "https://" + urllinkedin;
        }
        window.open(urllinkedin, "_blank");
      } else if (icon == "web") {
        var urlwebsite = this.website;
        if (!urlwebsite.match(/^https?:\/\//i)) {
          urlwebsite = "https://" + urlwebsite;
        }
        window.open(urlwebsite, "_blank");
      } else if (icon == "snapchat") {
        var snapchat = this.snapchat;
        if (!snapchat.match(/^https?:\/\//i)) {
          snapchat = "https://" + snapchat;
        }
        window.open(snapchat, "_blank");
      } else if (icon == "tiktok") {
        var tiktok = this.tiktok;
        if (!tiktok.match(/^https?:\/\//i)) {
          tiktok = "https://" + tiktok;
        }
        window.open(tiktok, "_blank");
      } else if (icon == "youtube") {
        var youtube = this.youtube;
        if (!youtube.match(/^https?:\/\//i)) {
          youtube = "https://" + youtube;
        }
        window.open(youtube, "_blank");
      }

    },

    async saveContact() {

      const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        }))

      await toDataURL(this.profileImg)
        .then(dataUrl => {
          var pic = dataUrl
          var strImage = pic.replace(/^data:image\/[a-z]+;base64,/, "");

          var urlwebsite = this.website;
          if (!urlwebsite.match(/^https?:\/\//i)) {
            urlwebsite = "https://" + urlwebsite;
          }
          var vCardsJS = require("vcards-js");
          var vCard = vCardsJS();
          vCard.lastName = this.name;
          vCard.organization = this.companyName;
          vCard.cellPhone = this.contactNumber;
          vCard.workPhone = this.officeNumber;
          vCard.workFax = this.faxNumber;
          vCard.title = this.role;
          vCard.email = this.email;

          vCard.photo.embedFromString(strImage, 'image/png')
          // vCard.photo.embedFromString(getBase64Img,'image/jpeg');
          if (urlwebsite != "https://") {
            vCard.url = urlwebsite
          }
          vCard.note = this.description
          var vCardString = vCard.getFormattedString();
          var url =
            "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardString);
          document.location.href = url;

        })




    },
    downloadPDF() {
      // this.pdfFile.substring(this.pdfFile.lastIndexOf("/") + 1).split("?")[0];
      var self = this;
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function () {
        console.log(self.pdfName)
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = self.pdfName
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      };
      xhr.open('GET', self.pdfFile);
      xhr.send();
    },

  },
  mounted() {
    setTimeout(() => {
      this.showSplashScreen = false;
    }, 3000);
  },

  watch: {
    companyNameComponent: function (newValue) {
      this.companyName = newValue;
    },
    contactNumberComponent: function (newValue) {
      this.contactNumber = newValue;
    },
    emailComponent: function (newValue) {
      this.email = newValue;
    },
    fbComponent: function (newValue) {
      this.fb = newValue;
    },
    instaComponent: function (newValue) {
      this.insta = newValue;
    },
    linkedinComponent: function (newValue) {
      this.linkedin = newValue;
    },
    nameComponent: function (newValue) {
      this.name = newValue;
    },
    profileImgComponent: function (newValue) {
      this.profileImg = newValue;
    },
    roleComponent: function (newValue) {
      this.role = newValue;
    },
    twitterComponent: function (newValue) {
      this.twitter = newValue;
    },
    websiteComponent: function (newValue) {
      this.website = newValue;
    },
    addressComponent: function (newValue) {
      this.address = newValue;
    },
    officeNumberComponent: function (newValue) {
      this.officeNumber = newValue;
    },
    faxNumberComponent: function (newValue) {
      this.faxNumber = newValue;
    },
    descriptionComponent: function (newValue) {
      this.description = newValue;
    },
    youtubeComponent: function (newValue) {
      this.youtube = newValue;
    },
    tiktokComponent: function (newValue) {
      this.tiktok = newValue;
    },
    whatsappComponent: function (newValue) {
      this.whatsapp = newValue;
    },
    teleComponent: function (newValue) {
      this.tele = newValue;
    },
    snapchatComponent: function (newValue) {
      this.snapchat = newValue;
    },
    picComponent: function (newValue) {
      this.pic = newValue;
    },
    socialArrComponent: function (newValue) {
      this.socialArr = newValue;
    },
    counterComponent: function (newValue) {
      this.counter = newValue;
    },
    additionalInfoComponents: function (newValue) {
      this.additionalInfo = newValue;
    },
    pdfFileComponent: function (newValue) {
      this.pdfFile = newValue;
    },
    pdfNameComponent: function (newValue) {
      this.pdfName = newValue;
    },
  },
  computed: {
    firstFiveSocials() {
      return this.socialArr.slice(0, 5)
    },
    remainingSocials() {
      return this.socialArr.slice(5)
    },
    dynamicPadding() {
      if ((this.address != '' && this.address !== undefined )|| (this.galleryImages != undefined && this.galleryImages.length != 0)) {
        return '0px';
      } else {
        console.log(window.innerWidth)
        if (window.innerWidth <= 767) {
          return '90px'; // Example for mobile screens
        } else if (window.innerWidth <= 1200) {
          return '300px'; // Example for tablet screens
        } else {
          return '300px'; // Example for desktop screens
        }
      }
    },
    transformStyle() {
      return `transform: translateX(-${this.currentIndex * 100}%);`;
    }
  }
};
</script>
<style scoped>
/* #app[data-v-app] {
} */

#MyAppDiv {
  background-color: #343434 !important;

}



.footerBtn {
  background-color: #343434;
  /* Ensure the footer button has the same background color */
  margin-bottom: 0;
  /* Eliminate margin to remove white space */
  padding-bottom: 0;
  /* Eliminate padding to remove white space */
  /* Other styles */
}

.bodyArea {
  margin: auto;
  /* Center align the body content */
  padding: 0;
  /* Remove default padding */
  max-width: 1440px;
  /* Set maximum width for content */
  background-color: #343434 !important;
  /* Set background color for the body */
}

.miama {
  font-family: miama;
}

@font-face {
  font-family: miama;
  src: url("../assets/fonts/Miama.ttf");
}

.inter-font {
  font-family: "Inter", sans-serif;
}

.headerSec {
  max-width: 100%;
  /* Make header section responsive */
  margin: auto;
  /* Center align the header section */
}

.coverpic {
  position: relative;
  width: 100%;
  /* Set width to 100% */
  height: 40vh;
  /* Set height to 70% of the viewport height */
}

.cover {
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Stretch the cover to fill its container */
  background-image: url(https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/Banner%2FVsatBanner.jpg?alt=media&token=618f34da-92ca-4674-a789-f7ea3d50cc91);
  /* Set cover image */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* Stretch the background image to cover the entire container */
}

.profile {
  z-index: 2;
  width: 100%;
  position: absolute;
  /* Position profile section absolutely */
  color: white;
  left: 50%;
  /* Align profile section to the center horizontally */
  bottom: -200px;
  /* Initially positioned below the container */
  transform: translate(-50%, -50%);
  text-align: center;
  overflow: hidden;
}

.proPic {
  border-radius: 100%;
  /* Make the profile picture round */
  border: 5px solid #2a2826;
  width: 20rem;
  /* If we want to get exact circle we need to add width and height both */
  height: 20rem;
  overflow: hidden;
  /* Object will cover the area of div */
  object-fit: cover;
  /* Objects center content will showed*/
  object-position: center;
}

/* profile */
.profContainer {
  margin-top: 120px;
  background-color: #343434;
}

.profSection {
  padding: 20px 20px 0px 20px;
  margin-top: -15px;
}

.profDiv {
  display: grid;
  justify-items: center;
}

.name {
  color: white;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 40px;
  /* font-size: 10px; */
  /* font-weight: 700; */
  text-align: center;
}

.profName {
  display: flex;
  align-items: center;
  gap: 20px;
}

.chan {
  margin: 0;
  padding-left: 0px;
}

.proftext {
  color: white;
  margin: 0;
  padding: 10px;

  font-weight: 700;
  text-align: center;
}

.profLink {
  margin: 0;
  padding: 16;

  color: #fff4c9;

  font-weight: 500;
  text-decoration: underline;
  text-align: center;
}

.profDescription {
  color: white;
  padding: 15px;

  font-weight: 500;
  text-align: center;
}

.btnContainer {
  margin-top:-15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.btnPhnNum {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  width: 60%;
  font-size: 20px;
  margin: 5px;
  background-color: #c1b398;
  padding: 10px;
  align-items: center;
  border: 2px solid #c0b397;
  border-radius: 10px;
  text-align: center;
  margin-left:10px !important;
  margin-right:10px!important;
}

.phone-icon {
  color: #f2e5c3;
}

.env-color {
  color: #f2e5c3;
}

.phn-nmb {
  color: white;
}

.emailAddress {
  color: white;
}

.btnE-mail {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  width: 60%;
  font-size: 20px;
  margin: 5px;
  background-color: #c1b398;
  border: 2px solid #c0b397;
  padding: 10px;
  color: white;
  border: none;
  border-radius: 10px;
  text-align: center;
  align-items: center;
}

/* download */
.downloadContainer {
  margin-top: 30px;
  /* padding: 0 10px; */
}

.downloadLink {
  color: #fceac2;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
}

.downloadDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.downloadText {
  margin: 0;
}

/* planning */
.planning {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  padding: 0 30px;
  margin-top: 10px;
  color: white;
}

.addressInfo {
  margin: 0;
  font-weight: 500;
  /* padding: 0 30px; */
  color: white;
}

.planningtext {
  margin: 0;
}

/* social */
.socialContainer {
  margin-top: 30px;
  padding-bottom: 10px;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  background-color: #343434;

}

.socialTitle {
  color: #fceac2;
  margin: 0;
  font-weight: 700;
}

.socialIcons{
  width:45px;
  height: 45px;
}

.socialLink {
  margin-top: 10px;
  max-height: 100px;
  display: flex;
  overflow-x: auto;
  gap: 16px;
}

.socialLink::-webkit-scrollbar {
  width: 0;
  display: none;
}
/* 
.socialIcons {
  color: #343434;
  display: flex;
  align-content: center;
  text-align: center;
  display: inline-block;
  align-items: center;
  line-height: 0px;

  border-radius: 100%;
  background-color: #e6e6e6;
  margin: 0 3px 3px 0;
} */

/* address */
.addressContainer {
  margin-top: 30px;
  padding-bottom: 100px;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  /* padding: 0 30px; */
  background-color: #343434;

}

.addressText {
  color: #fceac2;
  margin: 0;
  font-weight: 700;
}

/* sticky-button */
.footerBtn {
  width: 100%;
  background-color: #343434;
}

.sticky-button {
  z-index: 5;

  max-width: 1440px;
  box-sizing: border-box;
  display: inline-block;
  position: fixed !important;
  transform: translateX(-50%, -50%);
  /* Horizontally center */
  bottom: 0;
  /* Adjust as needed */
  padding: 24px 30px;
  font-size: 1.5em;
  background-color: #c1b398;
  color: white;
  border: none;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s ease-in;
}

.sticky-button:hover {
  transform: scale(1.01);
  box-shadow: 0 6px 4px darkgray;
}


/* .ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  transform: scale(0);
  animation: rippleEffect 0.5s linear;
}

@keyframes rippleEffect {
  to {
    transform: scale(4);
    opacity: 0;
  }
} */



/* For large devices (desktops and laptops) */
@media only screen and (min-width: 1200px) {
  .name{
    font-size: 47px !important;
  }
  .itemnode{ 
    font-size: 30px !important;
  }

  .aboveDescription {
    font-size: 20px;
  }

  .belowDescriptionText {
    font-size: 23px;
  }

  .belowDescriptionHeader {
    font-size: 25px;
  }

  .proPic {
    border: 5px solid #2a2826;
    width: 14rem;
    /* added same height */

    height: 14rem;
  }
  

  .sticky-button {
    width: 100%;
  }

  .socialIcons {
     font-size: 25px;
    width: 45px;
    height: 45px;
    /* width: 50px;
    height: 50px; */
  }
  
}

/* For medium devices (tablets) */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .name{
    font-size:47px !important;
  }
  
  .itemnode{
    font-size:30px !important;
  }

  .aboveDescription {
    font-size: 20px;
  }

  .belowDescriptionText {
    font-size: 18px;
  }

  .belowDescriptionHeader {
    font-size: 20px;
  }

  

  .proPic {
    border: 4px solid #2a2826;
    width: 11rem;
    /* added same height */

    height: 11rem;
  
  }

  .chan {
    margin: 0;
  }

  .name {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .sticky-button {
    width: 100%;
  }

  .socialIcons {
     font-size: 25px;
    width: 45px;
    height: 45px;
  }
  .coverpic {
    object-fit: cover;
    height: 20vh;
    /* Set height to 70% of the viewport height */
  }
}

/* For small devices (landscape phones) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .name{
    font-size:37px !important;

  }
  .itemnode{
    font-size:20px !important;
  }

  .aboveDescription {
    font-size: 15px;
  }

  .belowDescriptionText {
    font-size: 13px;
  }

  .belowDescriptionHeader {
    font-size: 15px;
  }

  .profile {
      /* using 60vh because the profile picture needs half on cover picture. cover photo size is 30vh.so the profile picture center will be 60vh from top */
    padding-top: 22vh !important;
  }

  .profContainer {
    margin-top: 0px;
  }

  .proPic {
    border: 4px solid #2a2826;
    width: 8rem;
    height: 8rem;

  }

  .chan {
    margin: 0;
  }

  .sticky-button {
    width: 100%;
  }
  .socialIcons {
    font-size: 25px;
    width: 45px;
    height: 45px;
    /* width: 50px;
    height: 50px; */
  }
  .coverpic {
    object-fit: cover;
    height: 20vh;
    /* Set height to 70% of the viewport height */
  }
}

/* For extra small devices (portrait phones) */
@media only screen and (max-width: 575px) {
  .name{
    font-size:37px !important;
  }

.itemnode{
    font-size:20px !important;
  }

  .aboveDescription {
    font-size: 15px;
  }

  .belowDescriptionText {
    font-size: 13px;
  }

  .belowDescriptionHeader {
    font-size: 15px;
  }

  .profile {
     /* using 60vh because the profile picture needs half on cover picture. cover photo size is 30vh.so the profile picture center will be 60vh from top */
    padding-top: 10vh !important;
  }

  .profContainer {
    margin-top: 0px;
  }

  .chan {
    margin: 0;
  }

  .coverpic {
    object-fit: cover;
    height: 20vh;
    /* Set height to 70% of the viewport height */
  }

  .proPic {
    border: 3px solid #2a2826;
    width: 9rem;
    /* added same height */
    height: 9rem;
  }

  .name {
    padding-top: 5px;
    padding-bottom: 12px;
    /* font-size: 28px; */
    /* font-weight: 700; */
    text-align: center;
  }

  .profName {
    gap: 12px;
  }

  .profContainer {
    margin-top: 70px;
  }

  .proftext {
    padding: 8px;

    font-weight: 600;
  }

  .profLink {
    padding-top: 5px;

    font-weight: 400;
  }

  .profDescription {
    padding: 10px;

    font-weight: 400;
    text-align: justify;
  }

  .btnPhnNum {
    gap: 16px;
    width: 100%;
    font-size: 15px;
    text-align: flex-start;
    justify-content: flex-start;
      align-items: center;
  }

  .btnE-mail {
    gap: 16px;
    width: 100%;
    font-size: 15px;
    justify-content: flex-start;
    text-align: flex-start;
      align-items: center;
  }



  .socialIcons {
    font-size: 25px;
    width: 45px;
    height: 45px;
    margin-bottom: 15px;
    /* width: 50px;
    height: 50px; */
  }

  .sticky-button {
    width: 100%;
    /* background-color: #343434; */
  }

  .popup {
    position: fixed;
    /* Fixed positioning relative to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    /* Cover the full width */
    height: 100%;
    /* Cover the full height */
    display: flex;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    background-color: rgba(0, 0, 0, 0.75);
    /* Semi-transparent black background */
    z-index: 3000;
    /* High z-index to ensure it's on top */
    overflow: hidden;
    /* Prevent scrolling on the background */
  }

  .popup-inner {
    position: relative;
    max-width: 400px;
    /* Limit the width of the inner content */
    padding: 20px;
    background-color: #343434;
    /* Background of the modal content */
    border-radius: 8px;
    /* Optional: Rounded corners for the modal */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    /* Shadow for 3D effect */
    text-align: center;
    /* Centering text if needed */
  }

  .fa-times {
    position: absolute;
    top: 10px;
    /* Adjust based on your design */
    right: 10px;
    /* Adjust based on your design */
    color: #333;
    /* Ensure it's visible on light backgrounds */
    cursor: pointer;
    /* Pointer to indicate clickable */
    font-size: 24px;
    /* Large enough to be easily clickable */
  }
}


/* Add custom styles here */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 400px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.splash-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #343434;
  z-index: 500;
}

.splash-screen {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .splash-container {
    flex-direction: column;
  }
}
.tooltip {
  display: none;
  color: white !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
}

.profLink:hover .tooltip {
  display: block; /* Show the tooltip on hover */
}

.slider {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  align-items: center;
}

.slides img {
  width:100%;
  height:100%;
  object-fit: contain;
  flex-shrink: 0;
  
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  padding: 10px;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
</style>
