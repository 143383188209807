import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC6AcHSH1aJ_V9WDNLGLxkUsqsJFsOznc0",
  authDomain: "thenetworktap-acb37.firebaseapp.com",
  projectId: "thenetworktap-acb37",
  storageBucket: "thenetworktap-acb37.appspot.com",
  messagingSenderId: "358052831931",
  appId: "1:358052831931:web:17fada6b4ac53e5e047f9e",
  measurementId: "G-P93LVHK0L6"
};

initializeApp(firebaseConfig)
export const db = getFirestore()