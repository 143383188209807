<template>
    <div>
        <header>
            <nav>
                <div class="container text-center pt-lg-5 pt-3">
                    <img class="img-fluid mx-auto" src="../assets/logo_2.png" alt="logo">
                </div>
            </nav>
            <div
                class="container d-flex flex-lg-row flex-column align-items-center justify-content-center gap-lg-5 gap-0 mt-lg-4">
                <h1 class="banner-title">Unleash</h1>
                <h3 class="banner-subtitle">Elevate Networking with <br /> Futuristic Digital Business Cards</h3>
            </div>
            <div class="container d-flex flex-lg-row flex-column align-items-center justify-content-center gap-2">
                <h1 class="banner-title">Your</h1>
                <div>
                    <div class="rectangle position-absolute" style="overflow: hidden; margin-right: 0px; margin-left: 8px;">
                    </div>
                    <h1 class="second-color position-relative mx-4">Professional</h1>
                </div>
            </div>
            <div class="container">
                <div class="d-flex flex-lg-row flex-column align-items-center gap-lg-4 gap-2">
                    <img class="banner-img rounded-pill mt-3 " src="images/bannerImg-1.png" alt="banner">
                    <h1 class="banner-title mt-lg-3">Power</h1>
                </div>
            </div>
        </header>
        <main>

            <section>
                <div class="container description">
                    <p class="about text-lg-end text-center">
                        Welcome to <b>The Network Tap</b>, where we redefine networking with our cutting-edge Digital
                        Business
                        Cards. Say goodbye to traditional paper cards and embrace a dynamic and interactive way to
                        showcase
                        your professional identity
                    </p>
                    <div
                        class="buttons d-flex flex-column-reverse flex-lg-row justify-content-lg-between align-items-center text-center">

                        <!-- <div class="d-flex flex-row align-items-center gap-3 mt-lg-0 mt-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" fill="none">
                                <path d="M2.41315 2.41321L17.8053 17.8054M17.8053 17.8054V2.41321M17.8053 17.8054H2.41315"
                                    stroke="#121F3E" stroke-width="2.30882" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <p class="mb-0">(Selected Work)</p>
                        </div> -->

                        <div class="custom-btn d-flex align-items-center rounded-pill text-white p-2" @click.prevent="saveContact()">
                            <p class="flex-grow-1 text-center mb-0 fw-medium" style="font-size:20px" >Add To Contacts</p>

                            <button class="arrow-right rounded-circle ms-2 border-0 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.29297 0.294024C8.48047 0.106581 8.73474 0.00128174 8.99986 0.00128174C9.26499 0.00128174 9.51926 0.106581 9.70676 0.294024L15.7059 6.29312C15.8933 6.48062 15.9986 6.73489 15.9986 7.00001C15.9986 7.26514 15.8933 7.51941 15.7059 7.70691L9.70676 13.706C9.51818 13.8881 9.26562 13.9889 9.00346 13.9866C8.7413 13.9844 8.49053 13.8792 8.30515 13.6938C8.11977 13.5084 8.01462 13.2577 8.01234 12.9955C8.01006 12.7334 8.11084 12.4808 8.29297 12.2922L12.5853 7.99986L1.00107 7.99986C0.735893 7.99986 0.481578 7.89452 0.29407 7.70701C0.106562 7.5195 0.0012207 7.26519 0.0012207 7.00001C0.0012207 6.73484 0.106562 6.48052 0.29407 6.29301C0.481578 6.1055 0.735893 6.00016 1.00107 6.00016L12.5853 6.00016L8.29297 1.70781C8.10553 1.52031 8.00023 1.26604 8.00023 1.00092C8.00023 0.735793 8.10553 0.481523 8.29297 0.294024Z"
                                        fill="#121F3E" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

            </section>
            <section class="mt-lg-5 mt-3"
                style="background: linear-gradient(to top, rgba(223, 196, 98, 0.21) 50%, transparent 50%);">
                <div class="d-flex justify-content-center mx-auto rounded-circle">
                    <!-- <video class="video-size rounded-5  " controls style=""> -->
                        <iframe class="video-size rounded-5 " width="1022" height="575" src="https://www.youtube.com/embed/YzdlYbWtej0" title="The Network Tap | Make the sustainable switch with TNT" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <!-- </video> -->
                </div>
            </section>



            <!-- Marquee section -->
            <headline :items="marquedataItems"></headline>
            <!-- Slider -->
            <mainSlider :items="sliderImages"></mainSlider>

            <!--  -->
            <section>
                <div
                    class="container d-lg-flex flex-column flex-lg-row justify-content-center align-items-center gap-lg-5 mx-auto">
                    <h1 class="title text-lg-start text-center">Our Products</h1>
                    <p class="subtitle text-lg-start text-center">Effortlessly design a stunning digital <br>
                        business card
                        that represents your brand.</p>
                </div>
                <leftNumberRightImageSection :items="leftNumberRightImageSectionOne"></leftNumberRightImageSection>
                <hr class="container">
                <rightNumberLeftImageSection :items="rightNumberLeftImageSectionTwo"></rightNumberLeftImageSection>
                <hr class="container">
                <leftNumberRightImageSection :items="leftNumberRightImageSectionThree">
                </leftNumberRightImageSection>
                <hr class="container">
                <rightNumberLeftImageSection :items="rightNumberLeftImageSectionFour"></rightNumberLeftImageSection>
                <hr class="container">
                <leftNumberRightImageSection :items="leftNumberRightImageSectionFive"></leftNumberRightImageSection>
                <hr class="container">
                <rightNumberLeftImageSection :items="rightNumberLeftImageSectionSix"></rightNumberLeftImageSection>
                <hr class="container">
                <leftNumberRightImageSection :items="leftNumberRightImageSectionSeven">
                </leftNumberRightImageSection>
                <hr class="container">
                <!-- -->
                <!-- Featured card -->
                <div class="container d-flex flex-lg-row flex-column-reverse justify-content-between gap-lg-5 gap-0 mt-5">
                    <div class="mt-lg-0 mt-4 justify-content-center">
                        <img class="w-75 mx-auto d-block" src="images/Rectangle 12.png" alt="">
                        <div class="d-lg-flex flex-lg-row justify-content-between align-items-center gap-lg-5 mx-auto mt-3">
                            <h1 class="title1" style="margin-left:10%"> Effortlessly stunning digital designs</h1>
                        </div>
                    </div>
                </div>

                <hr class="container">

            </section>

            <!-- We are outstanding section -->
            <section style="margin: 2rem;">
                <div class="container container-lg">
                    <h3 class=" client mb-4  title">We are Outstanding</h3>

                    <!-- col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 -->
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-4" v-for="data in headingTextAvatarData" :key="data.id">
                            <headingTextAvatar :data="data"></headingTextAvatar>
                        </div>
                    </div>
                </div>
            </section>

            <!--  -->

            <section class="mb-5"
                style="background-color: #173457; display: flex; justify-content: center; align-items: center;">
                <div class="py-5">
                    <h3 class="bottom-first">READY TO</h3>
                    <h1 class="bottom-second">Revolutionize your <br> networking game?</h1>
                    <div class="d-lg-flex flex-lg-row flex-column gap-3 mt-4">
                        <div class="d-flex flex-row align-items-center justify-content-center mx-auto gap-4">
                            <h4 class="bottom-third mb-0">Get started with</h4>
                            <img src="images/logo_white.png" alt="logo" style="width: 70px; ">
                        </div>
                        <div
                            class="custom-btn d-flex align-items-center justify-content-center mx-auto rounded-pill text-white p-2 border border-1 mt-lg-0 mt-3"  @click.prevent="saveContact()">
                            <p class="flex-grow-1 text-center mb-0 fw-medium" style="font-size:20px">Add to Contact</p>

                            <button class="arrow-right rounded-circle ms-2 border-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.29297 0.294024C8.48047 0.106581 8.73474 0.00128174 8.99986 0.00128174C9.26499 0.00128174 9.51926 0.106581 9.70676 0.294024L15.7059 6.29312C15.8933 6.48062 15.9986 6.73489 15.9986 7.00001C15.9986 7.26514 15.8933 7.51941 15.7059 7.70691L9.70676 13.706C9.51818 13.8881 9.26562 13.9889 9.00346 13.9866C8.7413 13.9844 8.49053 13.8792 8.30515 13.6938C8.11977 13.5084 8.01462 13.2577 8.01234 12.9955C8.01006 12.7334 8.11084 12.4808 8.29297 12.2922L12.5853 7.99986L1.00107 7.99986C0.735893 7.99986 0.481578 7.89452 0.29407 7.70701C0.106562 7.5195 0.0012207 7.26519 0.0012207 7.00001C0.0012207 6.73484 0.106562 6.48052 0.29407 6.29301C0.481578 6.1055 0.735893 6.00016 1.00107 6.00016L12.5853 6.00016L8.29297 1.70781C8.10553 1.52031 8.00023 1.26604 8.00023 1.00092C8.00023 0.735793 8.10553 0.481523 8.29297 0.294024Z"
                                        fill="#121F3E" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </section>


        </main>


        <footer>
            <section>

                <div class="container-fluid">
                    <div class="row text-center">
                        <div class="col-12 col-sm-6 col-lg-3 col-xl-3 mb-3">
                            <img src="../assets/logo_2.png" alt="logo" style="width: 56px; ">
                        </div>

                        <div class="col-12 col-sm-6 col-lg-3 col-xl-3">
                            <div class="d-flex gap-3 justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path
                                        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                </svg>
                                <!-- <div style="line-height: 3px; color: #8F8B89;">
                                    <p>1234 Elm Avenue, Suite 567 </p>
                                    <p>New York, NY 10001</p>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-lg-3 col-xl-3 text-center">
                            <p style="color: #8F8B89;">+65 9476 8087</p>
                        </div>

                        <div class="col-12 col-sm-6 col-lg-3 col-xl-3 text-center">
                            <p>Copyright © 2023 TNT</p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    </div>
</template>

<script>
import headline from './brochureComponent/headline.vue';
import leftNumberRightImageSection from './brochureComponent/leftNumberRightImageSection.vue';
import rightNumberLeftImageSection from './brochureComponent/rightNumberLeftImageSection.vue';
import headingTextAvatar from './brochureComponent/headingTextAvatar.vue';
import mainSlider from './brochureComponent/mainSlider.vue';


export default {
    components: {
        headline, leftNumberRightImageSection, rightNumberLeftImageSection, headingTextAvatar, mainSlider
    },
  
    data() {
        return {
            marquedataItems: [
                "Interactive and Dynamic Design",
                "Customizable to Reflect Your Brand",
                "One-Click Contact Information Exchange",
                "Contactless Networking",
                "Eco-Friendly Alternative",
                "Customizable Design",
                "Unlimited Updates",
                "Portable and Convenient",
                "Professional Digital Presence",
                "Easy Information Sharing",
                "Tech-Savvy Impression",
                "Cost-Effective Solution",
                "Secure Data Exchange"
            ],
            leftNumberRightImageSectionOne: [{
                number: "01",
                cardImage: "images/Rectangle 7.png",
                largeText: "Customized",
                boldText: "Super Convenient: ",
                normalText: "Digital name cards? Just a tap or a click away. No need to carry a stack of cards around. Easy to share too, can send to anyone, anytime, anywhere.",
            }
            ],
            rightNumberLeftImageSectionTwo: [{
                number: "02",
                cardImage: "images/Rectangle 15.png",
                largeText: "Circular",
                boldText: "Always Up-to-date: ",
                normalText: "Change your job, your number, or even your email? Just update your digital card lor. No need to print new ones. Save time and hassle",
            }
            ],
            leftNumberRightImageSectionThree: [{
                number: "03",
                cardImage: "images/Rectangle 9.png",
                largeText: "Eclipse",
                boldText: "Eco-friendly, Bro: ",
                normalText: "No paper, no waste. Good for the environment, lah. You help save trees and reduce waste, quite shiok feeling.",
            }
            ],
            rightNumberLeftImageSectionFour: [{
                number: "04",
                cardImage: "images/Rectangle 10.png",
                largeText: "Ombre",
                boldText: "Can Show More Stuff: ",
                normalText: "Digital cards can include links, videos, and even your portfolio. It's like a mini website, so it's easier to impress people",
            }
            ],
            leftNumberRightImageSectionFive: [{
                number: "05",
                cardImage: "images/Rectangle 11.png",
                largeText: "Square",
                boldText: "Easy to Track: ",
                normalText: "Some digital cards let you see who viewed or saved your card. Good for networking and follow-ups. Can better gauge interest.",
            }
            ],
            rightNumberLeftImageSectionSix: [{
                number: "06",
                cardImage: "images/Rectangle 12.png",
                largeText: "Classic",
                boldText: "No More Lost Cards: ",
                normalText: "Ever get a card and then lose it? With digital cards, no such problem. Save in your phone or cloud, always got backup.",
            }
            ], leftNumberRightImageSectionSeven: [{
                number: "07",
                cardImage: "images/Rectangle 8 (1).png",
                largeText: "Metal",
                boldText: "Cost-Effective: ",
                normalText: "No need keep printing new cards. Save money in the long run. Plus, creating and sending digital cards, sometimes even free!",
            }
            ],
            headingTextAvatarData: [{
                heading: "Quick customizing, fast delivery, perfect, quality packaging, highly recommended!",
                regularText: "The customization is very quickly looked into and completed. I recieved the card super fast too! And it works perfectly! Why would anyone use the traditional Name cards? You only need one and it comes in a very quality packaing. Highly Recommend!",
                avatarImage: "images/basicProfile.png",
                avatarName: "Leonard Kok",
                // designation: "CEO of Techpro",
            },
            {
                heading: "NFC namecard: handy, unique, customizable, reasonably priced, amazing service!",
                regularText: "NFC namecard is very handy as it save us the hassle of bringing a stack of namecards and running out of it The Network Tap makes it very unique for us as we are able to customize the design to our very own at very reasonable price. After sales service were amazing too",
                avatarImage: "images/sgqauatics.png",
                avatarName: "Singapore Aquatics",
                // designation: "CEO of Techpro",
            }
            ],
            sliderImages: [
                "images/Rectangle 8 (2).png",
                "images/Rectangle 12.png",
                "images/slider-2.png",
                "images/slider-3.png",

            ]
        };
    },
    methods:{
        async saveContact() {
        
            const toDataURL = url => fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
                }))
            
            await toDataURL("https://firebasestorage.googleapis.com/v0/b/thenetworktap-acb37.appspot.com/o/7sWBsoKld4dM9uYviwVw2Ed4hCl2%2Fprofile%2FprofileImg?alt=media&token=2ca62ae4-ff09-482c-90cc-8d2f46d96a51")
                .then(dataUrl => {
                var pic = dataUrl
                var strImage = pic.replace(/^data:image\/[a-z]+;base64,/, "");

                // var urlwebsite = this.website;
            // if (!urlwebsite.match(/^https?:\/\//i)) {
            //     urlwebsite = "https://" + urlwebsite;
            // }
            var vCardsJS = require("vcards-js");
            var vCard = vCardsJS();
            vCard.lastName ="Chang Wei An";
            vCard.organization = "The Network Tap";
            vCard.cellPhone = "+6594768087";
            vCard.title = "Sales & Marketing Manager";
            vCard.email = "thenetworktap@gmail.com";
            vCard.url = "https://thenetworktap.info/?user=7sWBsoKld4dM9uYviwVw2Ed4hCl2";
            
            vCard.photo.embedFromString(strImage, 'image/png')
              // vCard.photo.embedFromString(getBase64Img,'image/jpeg');
              // if (urlwebsite != "https://"){
              //     vCard.url = urlwebsite
              // }
              vCard.note = this.description
              var vCardString = vCard.getFormattedString();
              var url =
                  "data:text/vcard;charset=utf-8," + encodeURIComponent(vCardString);
              
              document.location.href = url;

                })
            },
  
    },


}
</script>
<style scoped>
@font-face {
    font-family: tiempose;
    src: url(/images/test-tiempos-headline-medium-italic.woff2);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');


header {
    padding-left: 160px;
    padding-right: 160px;
}

.banner-title {
    font-family: tiempose;
    font-size: 80px;
    color: #271E1B;
}

.banner-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    font-weight: 400;
    color: #121F3E;
}

.second-color {
    font-family: tiempose;
    font-size: 80px;
    color: #173457;
}

.rectangle {
    background-color: #DFC462;
    border-radius: 15px;
    width: 490px;
    height: 35px;
    margin-top: 58px;
}

.banner-img {
    width: 350px;
    margin-left: 80px;
}

.about {
    font-size: 12px;
    color: #8D98AF;
    width: 350px;
    display: inline-block;
    text-align: right;
}

.description {
    text-align: right;
}

.buttons {
    margin-left: 170px;

}

.arrow-right {
    background-color: #DFC462;
    width: 30px;
    height: 30px;

}

.arrow-right:hover {
    background-color: white;
}

.arrow {
    width: 1em;
    height: 1em;
}

.custom-btn {
    background-color: #173457;
    font-size: 10px;
    width: 220px;
}

.video {
    background-image: url('/assets/video.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    padding: 250px;
}

.play {
    height: 120px;
    width: 120px;
    padding-left: 20px;
    background-color: #FFFFFF69;
}

.play:hover {
    background-color: white;
}


.title {
    font-family: tiempose;
    font-size: 72px;
    color: #271E1B;
}

.title1 {
    font-family: tiempose;
    font-size: 50px;
    color: #271E1B;
}

.subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #121F3E;
}

.number {
    width: 125px;
    height: 120px;
    color: white;
    background-color: #173457;
    border: 0px;
    border-radius: 15px;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-weight: 600;
}

.number:hover {
    border-radius: 85px;
}

.logo {
    margin-top: 66px;
}

.front {
    font-family: Test Tiempos Headline;
    font-style: italic;
    font-weight: 600;
    font-size: 40px;
}

.image {
    border-radius: 10%;
}

.textColor {
    color: #121F3E;
}

.spacing {
    display: flex;
    justify-content: space-between;
}

.bottom-first {
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-weight: 500;
    color: #fff;
    font-style: italic;
}

.bottom-second {
    font-family: tiempose;
    font-size: 80px;
    color: #DFC462;
}

.bottom-third {
    font-family: tiempose;
    font-size: 35px;
    color: #fff;
}

.slider_image {
    width: 450px;
    height: 270px;
    overflow: hidden;
}

.slider-image1 {
    height: 270px;
    overflow: hidden;
}

.video-size {
    width: 70%;
    height: 600px;
    border-radius: 3rem;
    margin-bottom: 5rem;
}


@media (max-width: 1920px) {


    .img-fluid {
        width: 80px;
        height: 40px;
    }


}

@media (max-width: 430px) {
    .img-fluid {
        width: 50px;
        height: 25px;
        margin-bottom: 30px;
        text-align: center;
    }

    .banner-title {
        font-size: 50px;
    }

    header {
        padding-left: 20px;
        padding-right: 20px;
    }

    .banner-subtitle {
        text-align: center;
        font-size: 15px;
    }

    .second-color {
        font-size: 50px;
    }

    .rectangle {
        width: 300px;
        margin-top: 40px;
        height: 30px;
        margin-left: 10px;
    }

    .banner-img {
        width: 200px;
        margin-left: 0px;
    }

    .description {
        text-align: center;
    }

    .buttons {
        margin-left: 20px;
        margin-right: 20px;
    }

    .video {
        padding: 83px;
    }

    .play {
        height: 100px;
        width: 100px;
        padding-left: 15px;
    }

    .title {
        font-size: 36px;
    }

    .title1 {
        font-size: 20px;
    }

    .subtitle {
        font-size: 15px;
    }

    .number {
        width: 63px;
        height: 60px;
        border: 0px;
        border-radius: 15px;
        font-size: 20px;
        font-weight: 600;
    }

    .bottom-first {
        font-size: 20px;
        text-align: center;
    }


    .slider_image {
        width: 300px;
        height: 130px;

    }

    .slider-image1 {
        height: 130px;
    }

    .bottom-second {
        font-size: 40px;
        text-align: center;
    }

    .bottom-third {
        font-family: tiempose;
        font-size: 20px;
        color: #fff;
    }

    .video-size {
        width: 80%;
        height: auto;
        border-radius: 1rem;
        margin-bottom: 3rem;
    }

}
</style>