<template>
    <section>
        <div class=" my-5 overflow-hidden ">
            <div class="row">
                <div class="owl-carousel owl-theme">
                    <div class="item" v-for="(item, index) in items" :key="index">
                        <div class="card rounded-5">
                            <img :src="item" alt="" class="card-image-top slider_image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>
<style scoped>
@font-face {
    font-family: tiempose;
    src: url(/images/test-tiempos-headline-medium-italic.woff2);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');


header {
    padding-left: 160px;
    padding-right: 160px;
}

.banner-title {
    font-family: tiempose;
    font-size: 80px;
    color: #271E1B;
}

.banner-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    font-weight: 400;
    color: #121F3E;
}

.second-color {
    font-family: tiempose;
    font-size: 80px;
    color: #173457;
}

.rectangle {
    background-color: #DFC462;
    border-radius: 15px;
    width: 490px;
    height: 35px;
    margin-top: 58px;
}

.banner-img {
    width: 350px;
    margin-left: 80px;
}

.about {
    font-size: 12px;
    color: #8D98AF;
    width: 350px;
    display: inline-block;
    text-align: right;
}

.description {
    text-align: right;
}

.buttons {
    margin-left: 170px;

}

.arrow-right {
    background-color: #DFC462;
    width: 30px;
    height: 30px;

}

.arrow-right:hover {
    background-color: white;
}

.arrow {
    width: 1em;
    height: 1em;
}

.custom-btn {
    background-color: #173457;
    font-size: 10px;
    width: 220px;
}

.video {
    background-image: url('/assets/video.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    padding: 250px;
}

.play {
    height: 120px;
    width: 120px;
    padding-left: 20px;
    background-color: #FFFFFF69;
}

.play:hover {
    background-color: white;
}


.title {
    font-family: tiempose;
    font-size: 72px;
    color: #271E1B;
}

.title1 {
    font-family: tiempose;
    font-size: 50px;
    color: #271E1B;
}

.subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #121F3E;
}

.number {
    width: 125px;
    height: 120px;
    color: white;
    background-color: #173457;
    border: 0px;
    border-radius: 15px;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-weight: 600;
}

.number:hover {
    border-radius: 85px;
}

.logo {
    margin-top: 66px;
}

.front {
    font-family: Test Tiempos Headline;
    font-style: italic;
    font-weight: 600;
    font-size: 40px;
}

.image {
    border-radius: 10%;
}

.textColor {
    color: #121F3E;
}

.spacing {
    display: flex;
    justify-content: space-between;
}

.bottom-first {
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-weight: 500;
    color: #fff;
    font-style: italic;
}

.bottom-second {
    font-family: tiempose;
    font-size: 80px;
    color: #DFC462;
}

.bottom-third {
    font-family: tiempose;
    font-size: 35px;
    color: #fff;
}

.slider_image {
    width: 450px;
    height: 270px;
    overflow: hidden;
}

.slider-image1 {
    height: 270px;
    overflow: hidden;
}

.video-size {
    width: 70%;
    height: 600px;
    border-radius: 3rem;
    margin-bottom: 5rem;
}


.image_size {}


@media (max-width: 1920px) {


    .img-fluid {
        width: 80px;
        height: 40px;
    }


}

@media (max-width: 430px) {
    .img-fluid {
        width: 50px;
        height: 25px;
        margin-bottom: 30px;
        text-align: center;
    }

    .banner-title {
        font-size: 50px;
    }

    header {
        padding-left: 20px;
        padding-right: 20px;
    }

    .banner-subtitle {
        text-align: center;
        font-size: 15px;
    }

    .second-color {
        font-size: 50px;
    }

    .rectangle {
        width: 300px;
        margin-top: 40px;
        height: 30px;
        margin-left: 10px;
    }

    .banner-img {
        width: 200px;
        margin-left: 0px;
    }

    .description {
        text-align: center;
    }

    .buttons {
        margin-left: 20px;
        margin-right: 20px;
    }

    .video {
        padding: 83px;
    }

    .play {
        height: 100px;
        width: 100px;
        padding-left: 15px;
    }

    .title {
        font-size: 36px;
    }

    .title1 {
        font-size: 20px;
    }

    .subtitle {
        font-size: 15px;
    }

    .number {
        width: 63px;
        height: 60px;
        border: 0px;
        border-radius: 15px;
        font-size: 20px;
        font-weight: 600;
    }

    .bottom-first {
        font-size: 20px;
        text-align: center;
    }


    .slider_image {
        width: 300px;
        height: 130px;

    }

    .slider-image1 {
        height: 130px;
    }

    .bottom-second {
        font-size: 40px;
        text-align: center;
    }

    .bottom-third {
        font-family: tiempose;
        font-size: 20px;
        color: #fff;
    }

    .video-size {
        width: 80%;
        height: auto;
        border-radius: 1rem;
        margin-bottom: 3rem;
    }

}
</style>